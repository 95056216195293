import { createContext, useContext, useState } from "react";

import { PageWrapper } from "./PageWrapper";

import type { RefObject} from "react";

import type { PageWrapperProps } from "./PageWrapper";

export function PageWrapperWithRefContext(props: PageWrapperProps) {
  const [ref, setRef] = useState<HTMLDivElement | null>(null);

  return (
    <PageWrapperContext.Provider value={{ ref: { current: ref } }}>
      <PageWrapper {...props} ref={setRef} />
    </PageWrapperContext.Provider>
  );
}

interface PageWrapperRef {
  ref: RefObject<HTMLElement>;
}

const PageWrapperContext = createContext<PageWrapperRef | null>(null);

export function usePageWrapperRef() {
  const context = useContext(PageWrapperContext);

  if (!context) {
    throw new Error(
      "usePageWrapperRef should be wrapped in PageWrapperContext.Provider"
    );
  }

  return context;
}
