export const TAGS = {
  ACCOUNTS: "Accounts",
  BANKS_AUTOCOMPLETE: "BanksAutocomplete",
  CASH_MANAGEMENT_ACCOUNTS: "CashManagementAccounts",
  CASH_MANAGEMENT_CUSTOMER_CONFIG: "CashManagementCustomerConfig",
  CASH_MANAGEMENT_PROVIDERS: "CashManagementProviders",
  CASH_MANAGEMENT_SETTLEMENTS: "CashManagementSettlements",
  CASH_MANAGEMENT_SETTLEMENT_DETAILS: "CashManagementSettlementDetails",
  CIRCUIT_BREAKER_ALLOW_RULES: "CircuitBreakerAllowRules",
  CLIENT_NOTIFICATION_OUTSTANDING: "ClientNotificationOutstanding",
  CLIENT_NOTIFICATION_SETTINGS: "ClientNotificationSettings",
  CLIENT: "Client",
  CLIENTS_AUTOCOMPLETE: "ClientsAutocomplete",
  CLIENTS: "Clients",
  COUNTRIES: "Countries",
  CUSTOMER_AUTOCOMPLETE: "CustomersAutocomplete",
  CUSTOMER_COUNTRIES: "CustomerCountries",
  CUSTOMER_HIERARCHY_TREE: "CustomerHierarchyTree",
  CUSTOMER_HIERARCHY: "CustomerHierarchy",
  CUSTOMER_NAMES: "CustomerNames",
  CUSTOMER_PAYMENTS_PROVIDERS: "CustomerPaymentsProviders",
  CUSTOMER: "Customer",
  CUSTOMERS: "Customers",
  DYNAMIC_BENEFICIARIES: "DynamicBeneficiaries",
  GET_BENEFICIARIES: "GetBeneficiaries",
  PAYMENT: "Payment",
  PAYMENTS: "Payments",
  PAYMENTS_PROVIDERS: "PaymentsProviders",
  PAYMENT_PROCESSING: "PaymentProcessing",
  PAYOUT_DETAILS: "PayoutDetails",
  PAYOUT_NOTIFICATIONS: "PayoutNotifications",
  CREDIT_NOTIFICATIONS: "CreditNotifications",
  PAYOUTS_LIST: "PayoutsList",
  REFUND_STATUS: "RefundStatus",
  REFUNDS: "Refunds",
  REFUNDS_LIST: "RefundsList",
  REPORT_NOTIFICATION: "ReportNotification",
  SETTLEMENT_PREFERENCES: "SettlementPreferences",
  SETTLEMENT_LIMIT: "SettlementLimit",
  SETTLEMENT_BALANCE: "SettlementBalance",
  SETTLEMENT_SCHEDULE_CONFIG: "SettlementScheduleConfig",
  PROVIDER_BALANCE: "ProviderBalance",
  USER: "User",
  USER_NAMES: "UserNames",
  USERS: "Users",
  WORKFLOWS_DETAILS: "WorkflowsDetails",
  WORKFLOWS_LIST: "WorkflowsList",
  WORKFLOWS_HISTORY: "WorkflowHistory",
  PUBLIC_KEYS_LIST: "PublicKeysList",
  PUBLIC_KEY_DETAILS: "PublicKeyDetails",
  CHECKOUT: "Checkout",
} as const;
