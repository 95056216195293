import { COLORS } from "modules/checkout/models/CheckoutBranding";

/**
 * @deprecated use translations in the checkout module
 */
export const customersCheckoutBranding = {
  colors: {
    [COLORS.PRIMARY]: "Primary color",
    [COLORS.PRIMARY_CONTRAST]: "Primary text color",
    [COLORS.SECONDARY]: "Secondary color",
    [COLORS.SECONDARY_CONTRAST]: "Secondary text color",
    [COLORS.SIDEBAR]: "Sidebar color",
    [COLORS.SIDEBAR_CONTRAST]: "Sidebar text color",
    [COLORS.LINK]: "Link text color",
  },
  "default color": "{{color}} (default)",
};
