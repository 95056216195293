import type {
  LOGO_TYPE_DESKTOP,
  LOGO_TYPE_MOBILE,
  LOGO_TYPE_QR,
} from "./CustomerLogo";

export const COLORS = {
  PRIMARY: "primary",
  PRIMARY_CONTRAST: "primaryContrast",
  SECONDARY: "secondary",
  SECONDARY_CONTRAST: "secondaryContrast",
  SIDEBAR: "sidebar",
  SIDEBAR_CONTRAST: "sidebarContrast",
  LINK: "link",
} as const;

export type CheckoutBrandingColor = (typeof COLORS)[keyof typeof COLORS];

export const CHECKOUT_BRANDING_DEFAULT_COLORS: {
  [key in CheckoutBrandingColor]: string;
} = {
  [COLORS.PRIMARY]: "#1a63ab",
  [COLORS.PRIMARY_CONTRAST]: "#ffffff",
  [COLORS.SECONDARY]: "#1ea8a3",
  [COLORS.SECONDARY_CONTRAST]: "#ffffff",
  [COLORS.SIDEBAR]: "#363a40",
  [COLORS.SIDEBAR_CONTRAST]: "#f7f7f7",
  [COLORS.LINK]: "#1a63ab",
};

export type CheckoutBrandingColors = {
  [key in CheckoutBrandingColor]: string | null;
};

export interface CheckoutBrandingTexts {
  customerName?: string | null;
  tagline?: string | null;
}

export interface CheckoutBrandingLogos {
  [LOGO_TYPE_DESKTOP]?: string;
  [LOGO_TYPE_MOBILE]?: string;
  [LOGO_TYPE_QR]?: string;
}

export interface CustomerPreferencesBrandingSettings {
  colors?: CheckoutBrandingColors;
  customerLogos?: CheckoutBrandingLogos;
  texts?: CheckoutBrandingTexts;
}

export interface CustomerPreferencesBranding {
  active: boolean;
  preferences: CustomerPreferencesBrandingSettings;
}

export type CustomerPreferences = CustomerPreferencesBranding &
  CustomerPreferencesBrandingSettings;
