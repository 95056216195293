import * as Sentry from "@sentry/react";
import { eventChannel } from "redux-saga";
import {
  all,
  call,
  cancel,
  delay,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";

import { authApi, portalApi, TAGS } from "api/rtkApi";
import { getCustomer } from "modules/customers/api/Customers";
import * as UsersApi from "modules/user-management/api/Users";
import { callApi } from "sagas/helpers/api";
import {
  clearGlobalFilteredCustomer,
  setError,
  setSuccess,
  startLoading,
} from "store/appSlice";

import * as AuthApi from "../api/Auth";
import * as TwoFaApi from "../api/TwoFactorAuth";
import { callAuthApiSaga } from "../helpers/callAuthApi.saga";
import {
  authOperationPath,
  authOperationPaths,
  clearAccessTokenData,
  destroySession,
  fetchAuthCustomer,
  fetchCurrentUser,
  forceUpdateToken,
  isAt2FAStage,
  login,
  loginFailure,
  loginSCA,
  loginSuccess,
  logout,
  resendCode,
  selectAccessToken,
  selectCurrentCustomerId,
  selectCurrentUserCustomer,
  selectCurrentUserId,
  selectCurrentUserIsVoltEmployee,
  selectCurrentUsername,
  selectIsAuthorized,
  selectRefreshToken,
  selectRemainingTokenTimeMs,
  setAccessTokenData,
  setAuthCustomer,
  setCurrentUser,
  setEmail,
  twoFactorCall,
} from "./auth.slice";
import {
  logoutBroadcastChannel,
  prepareTokenData,
  tokenBroadcastChannel,
} from "./utils";

import type { Action, PayloadAction } from "@reduxjs/toolkit";
import type { EventChannel, Task } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import type { User } from "modules/user-management/models/User";
import type { UUID } from "utils";

import type { LoginCredentials } from "../models/LoginCredentials";
import type { FetchCurrentUserOptions } from "./auth.slice";
import type { LoginApiResult, SerializedAuthUser } from "./utils";

export const REFRESH_TOKEN_THRESHOLD_MS = 15 * 60 * 1000;
const RETRY_DELAY_MS = 5 * 1000;
const MAX_RETRIES = 5;
const LOGIN_RESULT = {
  success: "success",
  fail: "fail",
  logged: "logged",
};

function calculateRefreshDelay(remainingTokenTimeMs: number) {
  return Math.max(
    RETRY_DELAY_MS,
    remainingTokenTimeMs - REFRESH_TOKEN_THRESHOLD_MS
  );
}

export function* broadcastLogout() {
  const user: SagaReturnType<typeof selectCurrentUserId> =
    yield select(selectCurrentUserId);

  if (user) {
    yield call(logoutBroadcastChannel.sendUpdate, user);
  }
}

function createLogoutChannel() {
  return eventChannel<string>((emit) => {
    logoutBroadcastChannel.onUpdate(emit);
    const unsubscribe = () => {};

    return unsubscribe;
  });
}

export function* tabLogoutWatcher() {
  const channel: EventChannel<string> = yield call(createLogoutChannel);

  try {
    while (true) {
      yield take(channel);
      yield put(destroySession());
    }
  } catch (err) {
    console.error("Broadcast error:", err);
  } finally {
    yield channel.close();
  }
}

export function* performLoginSaga() {
  yield put(fetchCurrentUser());
  yield put(fetchAuthCustomer());
  yield put(loginSuccess());

  const userId: UUID = yield select(selectCurrentUserId);
  const isVoltEmployee: boolean = yield select(selectCurrentUserIsVoltEmployee);
  const userCustomer: User["customer"] | null = yield select(
    selectCurrentUserCustomer
  );

  setAnalyticsUserProperties({
    userCustomer,
    userId,
    isVoltEmployee,
  });
}

type SetAnalyticsUserPropertiesProps = {
  userId: UUID;
  isVoltEmployee: boolean;
  userCustomer: User["customer"] | null;
};

function setAnalyticsUserProperties({
  userId,
  userCustomer,
  isVoltEmployee,
}: SetAnalyticsUserPropertiesProps) {
  const type = isVoltEmployee ? "internal" : "external";
  const customer = userCustomer?.id ?? "Volt";

  window.hj?.("identify", userId, { type });
  window.gtag?.("set", "user_properties", {
    userId,
    type,
    customer,
  });
}

export function* resendCodeSaga() {
  const { twoFactorLogin } = authOperationPath;

  while (true) {
    try {
      yield take(resendCode);
      yield put(startLoading(twoFactorLogin));
      yield callApi(TwoFaApi.resendEmail2FACode);

      yield put(setSuccess(twoFactorLogin));
    } catch (error) {
      yield put(
        setError({
          path: twoFactorLogin,
          error,
        })
      );
    }
  }
}

export function* twoFactorSaga() {
  const { twoFactorLogin } = authOperationPath;

  while (true) {
    try {
      const {
        payload: { code },
      }: ReturnType<typeof twoFactorCall> = yield take(twoFactorCall);

      yield put(startLoading(twoFactorLogin));
      const tokenData: LoginApiResult = yield callApi(
        TwoFaApi.finalizeTwoFactorAuthEmail,
        code
      );

      yield all([
        put(setSuccess(twoFactorLogin)),
        put(clearGlobalFilteredCustomer()),
        put(setAccessTokenData(prepareTokenData(tokenData))),
      ]);
    } catch (error) {
      yield put(
        setError({
          path: twoFactorLogin,
          error,
        })
      );
    }
  }
}

export function* loginSCASaga(result: PayloadAction<LoginApiResult>) {
  yield all([
    put(clearGlobalFilteredCustomer()),
    put(setAccessTokenData(prepareTokenData(result.payload))),
  ]);
}

export function* loginSaga() {
  const { login: loginPath } = authOperationPath;

  try {
    const {
      payload: { rememberEmail, email, password },
    }: PayloadAction<LoginCredentials> = yield take(login.type);

    yield put(startLoading(loginPath));

    const result: LoginApiResult = yield callAuthApiSaga(AuthApi.authenticate, {
      email,
      password,
    });

    yield all([
      put(authApi.util.resetApiState()),
      put(setEmail(rememberEmail ? email : "")),
      put(clearGlobalFilteredCustomer()),
      put(setAccessTokenData(prepareTokenData(result))),
      put(setSuccess(loginPath)),
    ]);
  } catch (error) {
    yield put(
      setError({
        path: loginPath,
        error,
      })
    );
    yield put(loginFailure());
  }
}

export function* clearUserData() {
  yield call(broadcastLogout);
  yield put(clearAccessTokenData());
  yield put(portalApi.util.resetApiState());
  yield put(clearGlobalFilteredCustomer());
}

export function* authorizedLoginFlow() {
  let task: Task | undefined;
  let action:
    | ReturnType<
        typeof loginFailure | typeof loginSuccess | typeof setAccessTokenData
      >
    | undefined;
  while (true) {
    const isAuthorized: boolean = yield select(selectIsAuthorized);
    const is2Fa: boolean = yield select(isAt2FAStage);

    if (isAuthorized && !is2Fa) {
      return LOGIN_RESULT.logged;
    }

    if (!isAuthorized) {
      if (is2Fa) {
        task = yield fork(twoFactorSaga);
      } else {
        task = yield fork(loginSaga);
      }

      action = yield take([
        loginFailure.type,
        loginSuccess.type,
        setAccessTokenData.type,
        destroySession.type,
      ]);

      if (task) {
        yield cancel(task);
      }

      if (action?.type === loginFailure.type) {
        return LOGIN_RESULT.fail;
      } else if (action?.type !== setAccessTokenData.type) {
        return LOGIN_RESULT.success;
      }
    }
  }
}

export function* loginFlow() {
  while (true) {
    const authorized: string = yield call(authorizedLoginFlow);

    if (authorized === LOGIN_RESULT.logged || !authorized) {
      yield call(performLoginSaga);
    }

    if (authorized !== LOGIN_RESULT.fail) {
      yield race({
        logout: take(logout),
        destroy: take(destroySession.type),
        refresh: call(refreshTokenFlow),
      });
    }

    yield call(clearUserData);
  }
}

export function* refreshTokenFlow() {
  let retries = 0;

  while (true) {
    if (retries >= MAX_RETRIES) {
      console.info("Failed to refresh the token");
      // TODO: show message on the login screen?

      return;
    }

    const refreshToken: ReturnType<typeof selectRefreshToken> =
      yield select(selectRefreshToken);

    if (!refreshToken) {
      yield put(destroySession());
    }

    const tokenExpiryMs: number = yield select(selectRemainingTokenTimeMs);
    const refreshDelayMs = calculateRefreshDelay(tokenExpiryMs);

    try {
      const { forceUpdate, tokenUpdated } = yield race({
        delayUpdate: delay(refreshDelayMs),
        tokenUpdated: take(setAccessTokenData.type),
        forceUpdate: take(forceUpdateToken.type),
      });
      const isLeader: boolean = yield call(tokenBroadcastChannel.getIsLeader);

      if (forceUpdate?.type === forceUpdateToken.type && isLeader) {
        retries = 4;
      }

      if (isLeader && !tokenUpdated?.type) {
        yield call(refreshTokenSaga);
      }

      retries = 0;
    } catch (error) {
      yield call(tokenBroadcastChannel.validateLeader);
      // TODO: handle revoked token (sign out early)?
      // console.error("Refreshing token failed", error);
      yield delay(RETRY_DELAY_MS);
      retries += 1;
    }
  }
}

function* fetchAuthCustomerSaga() {
  const id: UUID | null = yield select(selectCurrentCustomerId);
  const operationPath = authOperationPaths.customer;

  if (!id) {
    return;
  }

  yield put(startLoading(operationPath));
  const payload = { id };

  try {
    const getCustomerAction: Action = yield call(getCustomer.initiate, payload);
    const promise: Promise<User> = yield put(getCustomerAction);

    yield promise;
    const { data } = yield select(getCustomer.select(payload));

    yield put(setAuthCustomer(data));
    yield put(setSuccess(operationPath));
  } catch (error) {
    console.error(error);
    yield put(
      setError({
        path: operationPath,
        error,
      })
    );
  }
}

function* fetchCurrentUserSaga(result: PayloadAction<FetchCurrentUserOptions>) {
  const operationPath = authOperationPath.currentUser;
  const userId: string = yield select(selectCurrentUserId);

  yield put(startLoading(operationPath));
  try {
    if (result.payload?.invalidateTag) {
      yield put(
        portalApi.util.invalidateTags([{ type: TAGS.USER, id: userId }])
      );
    }

    const actionCreator: Action = yield call(UsersApi.getUser.initiate, userId);
    const promise: Promise<User> = yield put(actionCreator);

    yield promise;
    const { data } = yield select(UsersApi.getUser.select(userId));

    yield put(setCurrentUser(data));
    // @ts-ignore
    promise.unsubscribe();
    yield put(setSuccess(operationPath));
  } catch (error) {
    yield put(
      setError({
        path: operationPath,
        error,
      })
    );
  }
}

function createTokenChannel() {
  return eventChannel<SerializedAuthUser>((emit) => {
    tokenBroadcastChannel.onUpdate(emit);
    const unsubscribe = () => {
      tokenBroadcastChannel.close();
    };

    return unsubscribe;
  });
}

function* accessTokenBroadcastWatcher() {
  const channel: EventChannel<SerializedAuthUser> =
    yield call(createTokenChannel);

  try {
    while (true) {
      const updateToken: SerializedAuthUser = yield take(channel);
      const token: SagaReturnType<typeof selectAccessToken> =
        yield select(selectAccessToken);
      const user: SagaReturnType<typeof selectCurrentUserId> =
        yield select(selectCurrentUserId);

      if (updateToken.accessToken !== token && token === null) {
        yield put(clearGlobalFilteredCustomer());
      }

      if (
        updateToken.accessToken !== null &&
        updateToken.accessToken !== token &&
        (user === null || updateToken.userId === user)
      ) {
        yield delay(100);
        yield put(setAccessTokenData(updateToken));
      }
    }
  } catch (err) {
    console.error("Broadcast error:", err);
  } finally {
    channel.close();
  }
}

function validateToken(token: SerializedAuthUser) {
  return Number(token.expirationTimestamp) > Number(new Date());
}

function* broadcastTokenSaga({ payload }: PayloadAction<SerializedAuthUser>) {
  const isTokenValid: boolean = yield call(validateToken, payload);

  if (isTokenValid) {
    yield call(tokenBroadcastChannel.sendUpdate, payload);
  } else {
    yield put(destroySession());
  }
}

function* watchFetchAuthCustomer() {
  yield takeLatest(fetchAuthCustomer.type, fetchAuthCustomerSaga);
}

export function* refreshTokenSaga() {
  const refreshToken: string = yield select(selectRefreshToken);
  const result: LoginApiResult = yield callApi(AuthApi.refresh, {
    refreshToken,
  });
  const tokenData = prepareTokenData(result);

  yield put(setAccessTokenData(tokenData));
}

function* updateSentryUserSaga() {
  const userId: UUID | null = yield select(selectCurrentUserId);
  const userEmail: string | null = yield select(selectCurrentUsername);

  if (userId) {
    Sentry.setUser({
      id: userId,
      email: userEmail ?? "",
    });
  } else {
    Sentry.setUser(null);
  }
}

export function* rootSaga() {
  yield fork(resendCodeSaga);
  yield fork(loginFlow);
  yield fork(accessTokenBroadcastWatcher);
  yield fork(watchFetchAuthCustomer);
  yield fork(tabLogoutWatcher);
  yield takeEvery(fetchCurrentUser.type, fetchCurrentUserSaga);
  yield takeEvery(setAccessTokenData.type, broadcastTokenSaga);
  yield takeEvery(
    [setAccessTokenData.type, clearAccessTokenData.type],
    updateSentryUserSaga
  );
  yield takeEvery(loginSCA.type, loginSCASaga);
}
