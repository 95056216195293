import { portalApi, TAGS } from "api/rtkApi";
import { SERVICES } from "constants/environment";
import { preparePaginationParams } from "utils/preparePaginationParams";

import { prepareSettlementsFilters } from "../utils/prepareSettlementsFilters";

import type { RTKMeta } from "models/RTKMeta";
import type { CreatedByParams } from "modules/common/models/CreatedBy";
import type { UUID } from "utils";
import type { ObjectWithId } from "utils/ObjectWithId";
import type { PaginationParams } from "utils/preparePaginationParams";

import type { SettlementsFilters } from "../constants/SettlementsFilters";
import type {
  OrderableColumns,
  Settlement,
  SettlementDetails,
  TriggerSettlementCode,
} from "../models/Settlements";

type GetSettlementsRequest = Pick<
  PaginationParams<OrderableColumns>,
  "limit" | "order" | "page" | "sortBy"
> &
  CreatedByParams & {
    customerHierarchyId?: UUID;
    customerId?: UUID;
    filters?: Partial<SettlementsFilters>;
  };

type GetSettlementsResponse = {
  settlements: Settlement[];
  totalItems: number;
};

type PostProductionSettlementRequest = {
  customerId: UUID;
};

type PostProductionSettlementResponse = {
  code: TriggerSettlementCode;
  message: string;
};

type PostSandboxSettlementRequest = {
  customerId: UUID;
};

type PostSandboxSettlementResponse = {
  message: string;
};

type PostSettleAccountRequest = {
  accountId: UUID;
};

type PostSettleAccountResponse = {
  message: string;
};

type GetSettlementDetailsRequest = ObjectWithId;
type GetSettlementDetailsResponse = SettlementDetails;

const extendedApi = portalApi.injectEndpoints({
  endpoints: (builder) => ({
    getSettlements: builder.query<
      GetSettlementsResponse,
      GetSettlementsRequest
    >({
      query: ({ filters, startDate, endDate, customerId, ...params }) => ({
        url: "/portal/cash-management/settlements",
        params: {
          ...preparePaginationParams(params),
          ...prepareSettlementsFilters({
            ...filters,
            startDate,
            endDate,
            customerId,
          }),
        },
        method: "GET",
      }),
      transformResponse: (settlements: Settlement[], meta: RTKMeta) => ({
        settlements,
        totalItems: Number(meta.response.headers.get("total-items")),
      }),
      providesTags: [TAGS.CASH_MANAGEMENT_SETTLEMENTS],
      extraOptions: {
        service: SERVICES.CONNECT,
      },
    }),
    postProductionSettlement: builder.mutation<
      PostProductionSettlementResponse,
      PostProductionSettlementRequest
    >({
      query: ({ customerId }) => ({
        url: `/customers/${customerId}/settlement/trigger`,
        method: "POST",
      }),
      invalidatesTags: [TAGS.CASH_MANAGEMENT_SETTLEMENTS],
      extraOptions: {
        service: SERVICES.CONNECT,
      },
    }),
    postSandboxSettlement: builder.mutation<
      PostSandboxSettlementResponse,
      PostSandboxSettlementRequest
    >({
      query: ({ customerId }) => ({
        url: `/customers/${customerId}/settlement/trigger-mock`,
        method: "POST",
      }),
      invalidatesTags: [TAGS.CASH_MANAGEMENT_SETTLEMENTS],
      extraOptions: {
        service: SERVICES.CONNECT,
      },
    }),
    getSettlementDetails: builder.query<
      GetSettlementDetailsResponse,
      GetSettlementDetailsRequest
    >({
      providesTags: (_, __, { id }) => [
        { type: TAGS.CASH_MANAGEMENT_SETTLEMENT_DETAILS, id },
      ],
      query: ({ id }) => ({
        url: `/portal/cash-management/settlements/${id}`,
        method: "GET",
      }),
      extraOptions: {
        service: SERVICES.CONNECT,
      },
    }),
    postSettleAccount: builder.mutation<
      PostSettleAccountResponse,
      PostSettleAccountRequest
    >({
      query: ({ accountId }) => ({
        url: `/portal/settlements/${accountId}/settle`,
        method: "POST",
      }),
      invalidatesTags: [TAGS.CASH_MANAGEMENT_SETTLEMENTS],
      extraOptions: {
        service: SERVICES.CONNECT,
      },
    }),
  }),
});

export const {
  useGetSettlementsQuery,
  useGetSettlementDetailsQuery,
  usePostProductionSettlementMutation,
  usePostSandboxSettlementMutation,
  usePostSettleAccountMutation,
} = extendedApi;
