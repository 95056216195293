/*eslint perfectionist/sort-objects: "error"*/

export const enGb = {
  additionalReference: "Additional reference",
  additionalReferenceForThisRefund: "Additional reference for this refund",
  addTotalBalance: "Add total balance",
  amount: "Amount",
  amountFrom: "Amount from",
  amountRefundedAlready: "Amount refunded already",
  amountTo: "Amount to",
  approve: "Approve",
  approved: "Approved",
  approvingRefunds: "Approving refunds",
  areYouSureYouWantToRejectThisRefund:
    "Are you sure you want to reject this refund?",
  availableForRefund: "<0>{{amount}}</0> available for refund",
  availableForRefundDaysRemaining:
    "<0>{{amount}}</0> available for refund, {{days}} day remaining",
  availableForRefundDaysRemaining_plural:
    "<0>{{amount}}</0> available for refund, {{days}} days remaining",
  availableToRefund: "Available to refund",
  backToAllRefunds: "Back to all refunds",
  cannotLoadUsernames: "Cannot load user names. Please try again later.",
  ccy: "CCY",
  choosingPayment: "Choosing payment",
  choosingPaymentRequiredToCreateANewRefund:
    "Choosing payment required to create a new refund",
  close: "Close",
  confirmed: "Confirmed",
  created: "Created",
  currency: "Currency",
  customer: "Customer",
  dateUtc: "Date (UTC)",
  dueDate: "Due date",
  externalRefundReference: "External refund reference",
  failed: "Failed",
  findAPaymentThatYouWantToRefund: "Find a payment that you want to refund.",
  full: "Full",
  fullRefundIsProcessing: "Full refund is processing",
  fullyRefunded: "Fully refunded",
  givenRefundCouldNotBeFound:
    "Given refund could not be found. Make sure you are on correct mode (production/sandbox).",
  id: "ID",
  incorrectAmountOfRefund: "Incorrect amount of refund",
  invalidRefundAmount: "Invalid refund amount",
  merchantInternalReference: "Merchant internal reference",
  moreFiltersText: "+ {{count}} more",
  newRefund: "New refund",
  noPaymentSelected: "No payment selected",
  noRefunds: "No refunds",
  partial: "Partial",
  partiallyRefunded: "Partially refunded",
  partialRefundIsProcessing: "Partial refund is processing",
  paymentAmount: "Payment amount",
  paymentDetails: "Payment details",
  paymentIsNotRefundable: "Payment is not refundable",
  payments: "Payments",
  processing: "Processing",
  refund: "Refund",
  refundAmount: "Refund amount",
  refundAmountMustBe: "Refund amount must be greater than {{value}}",
  refundDaysRemaining: "Refund days remaining",
  refundDetails: "Refund details",
  refundFailed: "Refund Failed",
  refundHasBeenApproved: "Refund has been approved.",
  refundHasBeenRejected: "Refund has been rejected.",
  refundId: "Refund ID",
  refundNotFound: "Refund not found",
  refundRejected: "Refund Rejected",
  refunds: "Refunds",
  refundsDetails: "Refunds details",
  refundsRequested: "Refunds requested",
  refundTransaction: "Refund transaction",
  refundValue: "Refund {{value}}",
  refundValueAndLeftDays:
    "{{value}} available for refund, {{count}} day remaining.",
  refundValueAndLeftDays_plural:
    "{{value}} available for refund, {{count}} days remaining.",
  refundValueOverAvailable:
    "{{value}} over the available refund limit. Please recalculate.",
  refundValueOverAvailable_plural:
    "{{value}} over the available refund limit. Please recalculate.",
  reject: "Reject",
  rejected: "Rejected",
  rejectingRefund: "Rejecting refund",
  requestedBy: "Requested by",
  requestedViaApi: "Requested via API",
  reset: "Reset",
  scaRequiredToApproveARefund: "SCA required to approve a refund",
  scaRequiredToCreateANewRefund: "SCA required to create a new refund",
  searchBy: "Search by",
  selectAll: "Select all",
  selectFilter: "Select filter",
  selectPaymentFirstToBeAbleToEnter:
    "Select payment first to be able to enter the refund amount.",
  showLess: "Show Less",
  sorrySomethingWentWrong:
    "Sorry, something went wrong. Please try again later.",
  status: "Status",
  theseRefundsHaveBeenCreatedForTransaction:
    "These refunds have been created for transaction ID {{id}}",
  toApproveANewRefundYouNeedToSetUpSCA:
    "To approve a refund you need to set up SCA. For multiple devices and browsers, use your phone or USB key as a passkey.",
  transactions: "Transactions",
  type: "Type",
  uniqueReference: "Unique reference",
  voltId: "Volt ID",
  voltPaymentId: "Volt payment ID",
  voltRefundId: "Volt refund ID",
  weHaveAcceptedYourRefundRequest:
    "We have accepted your refund request. Please bear with us while it's processed.",
  xRefundsApproved: "{{count}} refund has been approved.",
  xRefundsApproved_plural: "{{count}} refunds has been approved",
  xRefundsSelected: "{{count}} refund selected",
  xRefundsSelected_plural: "{{count}} refunds selected",
  youCanProvideAdditionalReferenceForThisRefund:
    "You can provide additional reference for this refund",
  youDontHaveAnyRefundsYet: "You don't have any refunds yet.",
  youHaveUnsavedFilters: "You have unsaved filters",
};
