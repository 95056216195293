/* eslint-disable no-nested-ternary */
import { ScopedCssBaseline, styled } from "@mui/material";
import { Content } from "@volt_developers/react-ui-components";
import { Suspense, useMemo } from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";

import { Can } from "components/can";
import { Loader } from "components/Loader";
import { authRoutes, universalRoutes } from "modules/auth/routes/auth";
import { Page403 } from "pages/Page403";
import { appRoutes } from "routes";
import { SentryRoute } from "sentry";
import { OldTheme } from "theme/OldTheme";

import { useAbility } from "./useAbility";

import type { ReactNode } from "react";

import type { AppAbilityType } from "context/ability/ability";
import type { Action } from "models/role/action";
import type { Subject } from "models/role/subject";
import type { Route as RouteModel } from "routes/RouteModel";

const flattenRoutes = (routes: RouteModel[]) => {
  const flattened: RouteModel[] = [];

  routes.forEach((route) => {
    if (route.children && route.children.length > 0) {
      flattened.push(...flattenRoutes(route.children));
    }

    flattened.push(route);
  });

  return flattened;
};

interface WrapperProps {
  children: ReactNode;
  newTheme: boolean;
  withoutContent: boolean;
}

const Wrapper = ({
  newTheme,
  withoutContent = false,
  children,
}: WrapperProps) =>
  newTheme ? (
    <FullWidthScopedCssBaseline>{children}</FullWidthScopedCssBaseline>
  ) : withoutContent ? (
    <OldTheme>{children}</OldTheme>
  ) : (
    <Content>
      <OldTheme>{children}</OldTheme>
    </Content>
  );

const FullWidthScopedCssBaseline = styled(ScopedCssBaseline)({
  width: "inherit",
});

interface AbilityComponentProps {
  Component: RouteModel["component"];
  can: { do: Action; on: Subject; field?: string } | null;
  props: NonNullable<unknown>;
}

function AbilityComponent({ can, Component, props }: AbilityComponentProps) {
  if (!Component) {
    return <Suspense fallback={<Loader />} />;
  }

  if (!can) {
    return (
      <Suspense fallback={<Loader />}>
        <Component {...props} />
      </Suspense>
    );
  }

  return (
    <Suspense fallback={<Loader />}>
      <Can do={can.do} field={can.field} on={can.on}>
        <Component {...props} />
      </Can>
      <Can do={can.do} field={can.field} on={can.on} not>
        <Page403 />
      </Can>
    </Suspense>
  );
}

export const useBuildChildRoutes = (
  routes: RouteModel[],
  withoutContent: boolean = false,
  ability?: AppAbilityType
) => {
  const { t } = useTranslation("common");

  return useMemo(
    () =>
      flattenRoutes(routes)
        .filter(({ path }) => path)
        .map((route) => {
          if (
            route.children?.length &&
            route.showChildrenInSidebar &&
            ability
          ) {
            const firstPossibleToViewRoute =
              route.children.find(
                ({ can }) => !can || (can && ability.can(can.do, can.on))
              ) ?? route.children[0];

            return {
              ...route,
              component: () => <Redirect to={firstPossibleToViewRoute.path} />,
            };
          }

          return route;
        })
        .map(
          ({
            path,
            can = null,
            newTheme = false,
            hasNestedSwitch = false,
            component: Component,
            title,
          }) => (
            <SentryRoute
              key={path}
              exact={!hasNestedSwitch}
              path={path}
              render={(props: NonNullable<unknown>) => (
                <Wrapper newTheme={newTheme} withoutContent={withoutContent}>
                  {title && (
                    <Helmet
                      title={(Array.isArray(title) ? title : [title])
                        .map((it) => t(it))
                        .join(" • ")}
                    />
                  )}
                  <AbilityComponent
                    Component={Component}
                    can={can}
                    props={props}
                  />
                </Wrapper>
              )}
            />
          )
        ),
    [ability, routes, withoutContent, t]
  );
};

export function useAppRoutes(
  appRoutesFilter?: (routes: RouteModel[]) => RouteModel[]
) {
  const ability = useAbility();
  const switchAuthRoutes = useBuildChildRoutes(authRoutes, true);
  const switchUniversalRoutes = useBuildChildRoutes(universalRoutes, true);
  const switchAppRoutes = useBuildChildRoutes(appRoutes, false, ability);

  const filteredValues = useMemo(() => {
    const isAppRoutesFilterProvided = typeof appRoutesFilter === "function";

    if (isAppRoutesFilterProvided) {
      const filteredAppRoutes = appRoutesFilter(appRoutes);
      const filteredAppPaths = filteredAppRoutes.map((route) => route.path);

      return {
        filteredAppRoutes,
        filteredAppPaths,
      };
    }

    return {};
  }, [appRoutesFilter]);

  const authPaths = authRoutes.map((route) => route.path);
  const appPaths = appRoutes.map((route) => route.path);
  const universalPaths = universalRoutes.map((route) => route.path);

  const allRoutes = [...switchAppRoutes, ...switchAuthRoutes].map((route) =>
    String(route.key)
  );

  return {
    switchAuthRoutes,
    switchAppRoutes,
    authPaths,
    appPaths,
    allRoutes,
    universalPaths,
    switchUniversalRoutes,
    ...filteredValues,
  };
}
