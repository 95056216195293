/*eslint perfectionist/sort-objects: "error"*/

export const enGb = {
  accountBalance: "Account balance",
  accountDetails: "Account details",
  accountIdentifier: "Account identifier",
  accountNotFound: "Account not found",
  accountNumber: "Account number",
  accounts: "Accounts",
  accountStatus: "Account status",
  addANewAccount: "Add a new account",
  additionalConfigurationForProcessingPayouts:
    "Additional configuration for processing payouts via ISX",
  additionalConfigurationForProcessingTransactions:
    "Additional configuration for processing transactions via {{providerName}} provider",
  additionalReferenceForThisRefund: "Additional reference for this refund",
  amountOfMoneyThatWillBeLeft:
    "Amount of money that will be left on account after any settlement.",
  amountSubjectToBeSettledInTheNextBatch:
    "Amount subject to be settled in the next batch",
  apiClient: "API Client",
  areYouSureYouWantToSettleAllAccounts:
    "Are you sure you want to settle all accounts?",
  areYouSureYouWantToSettleThisAccount:
    "Are you sure you want to settle this account?",
  availableBalanceOnYourAccount: "Available balance on your account",
  backToAllSettlements: "Back to all settlements",
  bankAccountDetailsLikeIbanAndSwift:
    "Bank account details like IBAN and Swift code (BIC). Account number, sort code or PIX key can be displayed for particular currencies.",
  beneficiary: "Beneficiary",
  bsb: "BSB",
  cancelled: "Cancelled",
  cashManagement: "Cash management",
  cashManagementAccount: "Cash management account",
  cashManagementAccountHasBeenUpdated:
    "Cash management account has been updated",
  cashManagementConfigDetails: "Cash management config details",
  cashManagementProviderNameConfig: "Cash management {{providerName}} config",
  cashManagementProviderNameConfigDetails:
    "Cash management {{providerName}} config details",
  chooseProviderAndCurrencyToSetAccountDetails:
    "Choose provider and currency to set account details.",
  clearJunctionConfiguration: "Clear Junction configuration",
  completed: "Completed",
  configurableProviders: "Configurable providers",
  configuration: "Configuration",
  configurationSettings: "Configuration settings",
  configurationStatus: "Configuration status",
  configureNotifications: "Configure notifications",
  configureProviderSettings: "Configure provider settings",
  configureProviderSettingsProviderName:
    "Configure provider settings - {{providerName}}",
  connect: "Connect",
  connectAccountsProviders: "Accounts providers",
  connectConfiguration: "Connect configuration",
  connectIsDisabledForThisMerchant: "Connect is disabled for this merchant",
  connectNotifications: "Connect notifications",
  createAccount: "Create account",
  createConfig: "Create config",
  created: "Created",
  createProviderConfig: "Create provider config",
  creatingSettlementInitiated: "Settlement have been requested",
  creditNotifications: "Credit notifications",
  creditNotificationsConfiguration: "Credit notifications configuration",
  creditNotificationsSettingsUpdatedSuccessfully:
    "Credit notifications settings updated successfully",
  currency: "Currency",
  daily: "Daily",
  details: "Details",
  editProviderConfig: "Edit provider config",
  enableSettlementBatches: "Enable settlement batches",
  enterYourAccountNumberAndBsbCode:
    "Enter your account number and BSB code to receive payments from AU bank accounts.",
  enterYourAccountNumberAndRoutingNumber:
    "Enter your account number and routing number to receive payments from US bank accounts.",
  enterYourAccountNumberAndSortCode:
    "Enter your account number and sort code to receive payments from UK bank accounts.",
  enterYourAccountNumberAndSwiftCode:
    "Enter your account number (IBAN) and Swift code (BIC) to receive payments into this account.",
  failed: "Failed",
  failedCanceled: "Failed (Canceled)",
  failingNotificationAlertEmail: "Failing notifications alert e-mail",
  frequency: "Frequency",
  friday: "Friday",
  givenAccountCouldNotBeFound: "Given account could not be found",
  givenSettlementCouldNotBeFound:
    "Given settlement could not be found. Make sure you are on correct mode (production/sandbox).",
  goBackToConnectSettings: "Go back to Connect settings",
  highRisk: "High risk",
  hmacKey: "HMAC key",
  hourly: "Hourly",
  iban: "IBAN",
  ifTheNotificationFails:
    "If the notification fails, an alert will be sent to this email.",
  initiated: "Initiated",
  interval: "Interval:",
  isxFinancialConfiguration: "ISX Financial configuration",
  isxMerchantId: "ISX Merchant ID",
  isxPayoutConfiguration: "ISX payout configuration",
  isxPayoutConfigurationUpdated: "ISX payout configuration has been updated",
  isxPayoutStatus: "ISX payout status",
  isxProviderNotFound: "ISX provider not found",
  itIsRequiredToConfigureProviderConfiguration:
    "It is required to configure provider configuration before you create virtual account using selected provider.",
  locationAndAcceptedPayments: "Location and accepted payments",
  lowRisk: "Low Risk",
  manual: "Manual",
  masterAccountNumberIban: "Master account number (IBAN)",
  minimumAmountRequired: "Minimum amount required to initiate a settlement.",
  monday: "Monday",
  monthDay: "Month day:",
  monthly: "Monthly",
  moreFiltersText: "+ {{count}} more",
  newVirtualAccount: "New virtual account",
  nextSettlement: "Next settlement",
  noAccounts: "No accounts",
  noConfigurableProviders: "No configurable providers",
  noSettlements: "No settlements",
  notifications: "Notifications",
  notificationSecret: "Notification secret",
  notificationSecretMustBeProviderByIsxFinancialDuringCustomerOnboarding:
    "Notification secret must be provided by ISX Financial during customer onboarding, it is used for validation/sign signature",
  notificationsSecret: "Notifications secret",
  notificationsSettings: "Notifications settings",
  notificationStatus: "Notification status",
  notificationUrlHttps: "Notification URL (https)",
  notProvided: "Not provided",
  noUnsettledOperations: "There are no unsettled transactions at the moment",
  openProviderConfiguration: "Open {{providerName}} provider configuration",
  payoutNotifications: "Payout notifications",
  payoutNotificationsConfiguration: "Payout notifications configuration",
  payoutNotificationsSettingsEnabled:
    "Payout notification settings enabled flag toggled successfully",
  payoutNotificationsSettingsHaveBeenCreated:
    "Payout notifications settings have been created",
  payoutNotificationsSettingsHaveBeenUpdated:
    "Payout notifications settings have been updated",
  privateKey: "Private key",
  processingPayoutsViaISXFinancialProvider:
    "Processing payouts via ISX Financial provider",
  provider: "Provider",
  providerConfig: "Provider config",
  providerConfigUpdated: "Provider config has been updated",
  providerName: "Provider name",
  providerNameProviderConfig: "{{providerName}} provider config",
  providers: "Providers",
  providerSettings: "Provider settings",
  provideTheRiskScore:
    "Provide the risk score assigned by Clear Junction during customer onboarding",
  refreshAllBalances: "Refresh all balances",
  rejected: "Rejected",
  reportNotifications: "Report notifications",
  reportNotificationsConfiguration: "Report notifications configuration",
  reportNotificationsSettingsUpdatedSuccessfully:
    "Report notifications settings updated successfully",
  reserve: "Reserve",
  riskScore: "Risk score",
  routingNumber: "Routing number",
  saturday: "Saturday",
  saveChanges: "Save changes",
  schedule: "Schedule",
  select: "Select",
  selectCustomer: "Select customer",
  selectFilter: "Select filter",
  selectProviderFirstToChooseACurrency:
    "Select provider first to choose a currency",
  setSettlementThresholdAndOtherRules:
    "Set settlement threshold and other rules.",
  settleAllAccounts: "Settle all accounts",
  settlement: "Settlement",
  settlementAmount: "Settlement amount",
  settlementAmountNotProvided: "Settlement amount not provided",
  settlementBatchesSent: "Settlement batches have been sent",
  settlementId: "Settlement ID",
  settlementModel: "Settlement model",
  settlementNotFound: "Settlement not found",
  settlementOfAllAccounts: "Settlement of all accounts",
  settlementOfThisAccount: "Settlement of this account",
  settlementReference: "Settlement reference",
  settlements: "Settlements",
  settlementSchedule: "Settlement schedule",
  settlementSettings: "Settlement settings",
  settlementThreshold: "Settlement threshold",
  settleThisAccount: "Settle this account",
  showLess: "Show Less",
  sorryTheProviderConfigWasNotFound: "Sorry, the provider config was not found",
  sorryTheProviderWasNotFound: "Sorry, the provider was not found",
  sortCode: "Sort code",
  startingTime: "Starting time:",
  status: "Status",
  subdomain: "Subdomain",
  sunday: "Sunday",
  swiftCodeBic: "Swift code (BIC)",
  testPayoutNotifications: "Test payout notifications",
  thereAreNoConfigurableProvidersAtThisMoment:
    "There are no configurable providers at this moment.",
  thursday: "Thursday",
  toReceivePaymentsFromOtherCountries:
    "To receive payments from other countries",
  toViewConnectSelectCustomer:
    "To view Connect settings, please select a customer in the select customer menu in the upper left corner.",
  transactions: "Transactions",
  trigger: "Trigger",
  triggerType: "Trigger type",
  tuesday: "Tuesday",
  uniqueIdForApi: "Unique ID for API",
  updateConfig: "Update config",
  updateNotificationsSettings: "Update notifications settings",
  virtualAccount: "Virtual account",
  virtualAccountCreatedSuccessfully: "Virtual account created successfully",
  virtualAccounts: "Virtual accounts",
  voltAccountId: "Volt Account ID",
  voltCanSendANotificationWhen:
    "Volt can send a notification when reports (e.g. for settlements) are available.",
  voltCanSendANotificationWhenCreditsAreReceivedIntoYourAccount:
    "Volt can send a notification when credits are received into your account.",
  voltCanSendANotificationWhenProcessingOfThePayoutIsCompleted:
    "Volt can send a notification when processing of the payout is completed.",
  voltCustomerId: "Volt customer ID",
  voltSettlementId: "Volt settlement ID",
  walletId: "Wallet ID",
  wednesday: "Wednesday",
  weekly: "Weekly",
  weHaveReceivedTestNotification:
    "We have received test notification request. Notifications will be disabled if webhook is incorrect",
  weHaveSettledYourAccount: "We have settled your account",
  youDoNotHavePermissionToProvideISX:
    "You don't have permission to provide ISX payout configuration. Do talk to out support team if you think there is an issue.",
  youDontHaveAnyAccountsYet: "You don't have any accounts yet.",
  youDontHaveAnySettlementsYet: "You don't have any settlements yet.",
  yourNameForThisAccount: "Your name for this account",
};
