import { Button } from "@material-ui/core";
import { CheckboxFilters, Plus } from "@volt_developers/react-ui-components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { BLOCK_RULE_ALL_TYPES, BLOCK_RULE_TYPE_BANK } from "../constants/Rules";
import {
  FILTER_ACTIVE_DISABLED,
  FILTER_ACTIVE_ENABLED,
  FILTER_ACTIVE_INDETERMINATE,
  useBlocklistFilters,
} from "../hooks/useBlocklistFilters";

import type {
  CheckboxListGroups,
  FieldProps,
} from "@volt_developers/react-ui-components";
import type { MouseEvent } from "react";

import type { CIRCUIT_BREAKER_BLOCKLIST_FILTER_ACTIVE } from "../hooks/useBlocklistFilters";
import type { BlockRuleType } from "../models/BlockRules";

export const FILTER_CHECKBOX_GROUP_TYPES = "types";
export const FILTER_CHECKBOX_GROUP_ACTIVE = "active";

export const ALL_KEYS_ID = "all_keys";

export interface BlocklistFiltersDropdownProps {
  [FILTER_CHECKBOX_GROUP_ACTIVE]: CIRCUIT_BREAKER_BLOCKLIST_FILTER_ACTIVE;
  [FILTER_CHECKBOX_GROUP_TYPES]: BlockRuleType[];
  onChange: (filters: {
    active?: boolean | null;
    types?: BlockRuleType[];
  }) => void;
}
export function BlocklistFiltersDropdown({
  types,
  active,
  onChange,
}: BlocklistFiltersDropdownProps) {
  const { t } = useTranslation(["circuitBreaker"]);
  const { classes } = useBlocklistFilters();

  const isNoneTypeSelected = types.length === 0;
  const isLastTypeSelected = types.length === 1;
  const isAllTypesSelected = types.length === BLOCK_RULE_ALL_TYPES.length;
  const isLastTypeToSelect = types.length === BLOCK_RULE_ALL_TYPES.length - 1;

  const [checkboxes, setCheckboxes] = useState<CheckboxListGroups>();

  const [popoverAnchor, setPopoverAnchor] = useState<HTMLButtonElement | null>(
    null
  );

  useEffect(() => {
    const createFieldWithLabel = (key: string) => ({
      id: key,
      label: t(key),
    });

    setCheckboxes({
      [FILTER_CHECKBOX_GROUP_TYPES]: [
        {
          id: ALL_KEYS_ID,
          label: t(ALL_KEYS_ID),
          indeterminate: !isAllTypesSelected && !isNoneTypeSelected,
          checked: isNoneTypeSelected,
        },
        ...BLOCK_RULE_ALL_TYPES.map((type) => ({
          ...createFieldWithLabel(type),
          checked: types.includes(type) || isNoneTypeSelected,
          disabled: types.includes(type) && isLastTypeSelected,
        })),
      ],
      [FILTER_CHECKBOX_GROUP_ACTIVE]: [
        {
          ...createFieldWithLabel(FILTER_ACTIVE_ENABLED),
          checked: [
            FILTER_ACTIVE_ENABLED,
            FILTER_ACTIVE_INDETERMINATE,
          ].includes(active),
          disabled: active === FILTER_ACTIVE_ENABLED,
        },
        {
          ...createFieldWithLabel(FILTER_ACTIVE_DISABLED),
          checked: [
            FILTER_ACTIVE_DISABLED,
            FILTER_ACTIVE_INDETERMINATE,
          ].includes(active),
          disabled: active === FILTER_ACTIVE_DISABLED,
        },
      ],
    });
  }, [
    types,
    active,
    t,
    isAllTypesSelected,
    isLastTypeSelected,
    isNoneTypeSelected,
  ]);

  const handleTypesGroup = (updatedField: FieldProps) => {
    const isAllKeysOrLastNotSelectedItemClicked =
      updatedField.id === ALL_KEYS_ID ||
      (isLastTypeToSelect && updatedField.checked === true);

    const isAllKeysClickedAndAllSelected =
      updatedField.id === ALL_KEYS_ID &&
      updatedField.checked === false &&
      types.length === 0;

    const isFirstItemToUnselectClicked =
      updatedField.checked === false && isNoneTypeSelected;

    const isAnyOtherItemChecked =
      updatedField.checked === true && !isNoneTypeSelected;

    if (isAllKeysClickedAndAllSelected) {
      return onChange({ types: [BLOCK_RULE_TYPE_BANK] });
    }

    if (isAllKeysOrLastNotSelectedItemClicked) {
      return onChange({ types: [] });
    }

    if (isFirstItemToUnselectClicked) {
      return onChange({
        types: BLOCK_RULE_ALL_TYPES.filter((type) => type !== updatedField.id),
      });
    }

    if (isAnyOtherItemChecked) {
      return onChange({
        types: [...types, updatedField.id as BlockRuleType],
      });
    }

    return onChange({
      types: types.filter((type) => type !== updatedField.id),
    });
  };

  const handleActiveGroup = (updatedField: FieldProps) => {
    const isActiveIndeterminateAndEnabledClicked =
      active === FILTER_ACTIVE_INDETERMINATE &&
      updatedField.id === FILTER_ACTIVE_ENABLED;

    const isActiveIndeterminateAndDisabledClicked =
      active === FILTER_ACTIVE_INDETERMINATE &&
      updatedField.id === FILTER_ACTIVE_DISABLED;

    if (isActiveIndeterminateAndEnabledClicked) {
      return onChange({ active: false });
    }

    if (isActiveIndeterminateAndDisabledClicked) {
      return onChange({ active: true });
    }

    return onChange({ active: null });
  };

  const onChecked = (updatedField: FieldProps, group?: string) => {
    switch (group) {
      case FILTER_CHECKBOX_GROUP_TYPES: {
        return handleTypesGroup(updatedField);
      }

      case FILTER_CHECKBOX_GROUP_ACTIVE: {
        return handleActiveGroup(updatedField);
      }
      default: {
        break;
      }
    }
  };

  const handleClick = ({ currentTarget }: MouseEvent<HTMLButtonElement>) => {
    setPopoverAnchor(currentTarget);
  };

  const handleClose = () => {
    setPopoverAnchor(null);
  };

  return (
    <CheckboxFilters
      checkboxes={checkboxes ?? {}}
      open={Boolean(popoverAnchor)}
      popoverAnchor={popoverAnchor}
      onChecked={onChecked}
      onClose={handleClose}
    >
      <Button
        classes={{ root: classes.button }}
        color="primary"
        data-testid="circuit-breaker-blocklist-filter-add-button"
        startIcon={<Plus className={classes.plusIcon} />}
        onClick={handleClick}
      >
        <div className={classes.buttonText}>{t("Add filter")}</div>
      </Button>
    </CheckboxFilters>
  );
}
