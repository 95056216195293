/*eslint perfectionist/sort-objects: "error"*/

export const enGb = {
  accessLevel: "Access Level",
  active: "Active",
  add: "Add",
  addANewUser: "Add a new user",
  addNewUser: "Add new user",
  areYouSureYouWantToDeactivateSca:
    "Are you sure you want to deactivate strong customer authentication for this user? Re-enabling will require user to setup SCA again.",
  backToAllUsers: "Back to all users",
  checkedPermissions: "{{checked}} / {{total}} permissions",
  configuration: "Configuration",
  couldNotRemoveSca:
    "Could not remove strong customer authentication. Please try again later.",
  customer: "Customer",
  customers: "Customers",
  deactivateUser: "Deactivate User",
  edit: "Edit",
  editUser: "Edit user",
  email: "Email",
  "Email address": "Email address",
  fuzeboxAccess: "Fuzebox Access",
  generalInformation: "General information",
  givenUsercouldNotBeFound:
    "Given user could not be found. Make sure you are on correct mode (production/sandbox).",
  inactive: "Inactive",
  isThisAVoltAdminAccount: "Is this a Volt Admin Account?",
  locked: "Locked",
  "Merchant Users": "Merchant Users",
  merchantUsers: "Merchant Users",
  Name: "Name",
  pageDescription: `Users who can access the Volt merchant portal from your organisation.
Admin users can add other users, configure applications and make changes to your merchant account.
Standard users can view your transactions and invoices.`,
  Password: "Password",
  "Password confirmation": "Password confirmation",
  Permissions: "Permissions",
  permissionsAmount: "{{count}} permission",
  permissionsAmount_plural: "{{count}} permissions",
  permissionsPreviewSectionDescription:
    "This is the overview of permissions {{name}} has on Fuzebox",
  pleaseOnlySelectForVoltEmployees: "Please only select for Volt employees",
  reactivateUser: "Reactivate User",
  removeSca: "Remove SCA",
  removeStrongCustomerAuthentication: "Remove strong customer authentication",
  resetPassword: "Reset Password",
  roles: "roles",
  saveChanges: "Save changes",
  sca: "SCA",
  scaHasBeenSuccessfullyRemovedForThisUser:
    "Strong customer authentication has been successfully removed for this user.",
  scaIsEnabled: "SCA is enabled",
  scaIsNotEnabled: "SCA is not enabled",
  security: "Security",
  status: "Status",
  strongCustomerAuthentication: "Strong customer authentication",
  theUserHasBeenCreated:
    "The user has been created. An e-mail verification link has been sent to {{email}} to finish the set-up.",
  theUserHasBeenModified: "The user has been modified.",
  twoFaAuthenticatorCannotBeDisabledForVoltAdmins:
    "Two factor authentication cannot be disabled for Volt Admins",
  twoFactorAuthenticator: "Two-factor authentication",
  twoFaHasBeenSuccessfullyRemovedForThisUser:
    "Two-factor authentication has been successfully removed for this user.",
  twoFaIsEnabled: "Two-factor authentication is enabled",
  twoFaIsNotEnabled: "Two-factor authentication is disabled",
  user: "User",
  userHasAccessToAllCustomers:
    "The user has access to all Volt customer accounts",
  userHasAccessToCustomersWithinHierarchy:
    "The user has access to all customer accounts within the hierarchy",
  userNotFound: "User not found",
  users: "Users",
  voltAdminUser: "Volt admin user",
  youDontHaveAnyUsersSetUpRightNowDoTalkToYourSystemAdministrator:
    "You don't have any users set up right now, do talk to your system administrator if you think there's an issue or our support team",
  youDontHaveAnyUsersSetUpRightNowLetsGetStarted:
    "You don't have any users set up right now, let's get started",
  youDontHaveEnoughPermissionsToEditThisUser:
    "You don't have enough permissions to edit this user",
};
