import type { UUID } from "utils";

export const TEST_IDS = {
  amountRefundedAlready: "amount-refund-already",
  availableAmountToRefund: "available-amount-to-refund",
  createdByCell: "created-by-cell",
  createNewRefundButton: "create-new-refund-button",
  customerCell: "customer-cell",
  refundList: {
    getRow: (id: UUID) => `refund-row-${id}`,
    getCheckboxCell: (id: UUID) => `refund-row-${id}-checkbox`,
    getDateCell: (id: UUID) => `refund-row-${id}-date`,
    getCreatedByCell: (id: UUID) => `refund-row-${id}-created-by`,
    getIdCell: (id: UUID) => `refund-row-${id}-id`,
    getCustomerCell: (id: UUID) => `refund-row-${id}-customer`,
    getAmountCell: (id: UUID) => `refund-row-${id}-amount`,
    getStatusCell: (id: UUID) => `refund-row-${id}-status`,
  },
  getRefundStatus: (status: string) => `refund-${status}-status`,
  filters: {
    content: "refunds-filters-content",
    currencyFilter: "refunds-filters-currency-filter",
    errorMessage: "refunds-filters-error-message",
    modal: "refunds-filters-modal",
    statusFilter: "refunds-filters-status-filter",
    typeFilter: "refunds-filters-type-filter",
    amountFromFilter: "refunds-filters-amountFrom-filter",
    amountToFilter: "refunds-filters-amountTo-filter",
    getAmountFilterInput: (prefix: string) => `${prefix}-input`,
  },
  noPaymentSelected: "no-payment-selected",
  paymentsBreadcrumbLink: "payments-breadcrumb-link",
  externalReference: "external-reference",
  refundAmount: "refund-amount",
  refundAmountError: "refund-amount-text-error",
  refundAmountText: "refund-amount-helper-text",
  refundApproveButton: "refund-approve-button",
  refundDaysRemaining: "refund-days-remaining",
  refundDetails: "refund-details-card",
  refundDetailsAmount: "refund-details-amount",
  refundDetailsCurrency: "refund-details-currency",
  refundDetailsRefundId: "refund-details-refund-id",
  refundDetailsExternalRefundReference:
    "refund-details-external-refund-reference",
  refundDetailsRequestedBy: "refund-details-requested-by",
  refundDetailsType: "refund-details-type",
  refundDueDate: "refund-due-date",
  refundFailureToast: "refund-failure-toast",
  refundItemLoader: "refund-item-loader",
  refundRejectButton: "refund-reject-button",
  refundsBreadcrumbLink: "refunds-breadcrumb-link",
  refundsRequested: "refunds-requested",
  refundStatus: "refund-status",
  refundStatusTag: "refund-status-tag",
  refundStatusTimeline: "refund-status-timeline-card",
  refundSuccessToast: "refund-success-toast",
  refundPaymentDetailsAmount: "refund-payment-details-amount",
  refundPaymentDetailsCurrency: "refund-payment-details-currency",
  refundPaymentDetailsCustomerName: "refund-payment-details-customer-name",
  refundPaymentDetailsId: "refund-payment-details-id",
  refundPaymentDetailsMerchantInternalReference:
    "refund-payment-details-merchant-internal-reference",
  refundPaymentDetailsUniqueReference:
    "refund-payment-details-unique-reference",
  rejectRefundConfirmModalConfirmButton:
    "reject-refund-confirm-modal-confirm-button",
  searchPaymentAutocomplete: "search-payment-autocomplete",
  searchPaymentItem: "search-payment-item",
  searchPaymentOptionId: "search-payment-option-id",
  searchPaymentOptionUniqueReference: "search-payment-option-unique-reference",
  searchPaymentSelect: "search-payment-select",
  totalBalanceButton: "total-balance-button",
  paymentAmount: "payment-amount",
  paymentAmountTag: "payment-amount-tag",
} as const;
