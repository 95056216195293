import { getPaginationInfoFromHeaders } from "api/helpers/pagination";
import { portalApi, TAGS } from "api/rtkApi";
import { DEFAULT_PAGINATION } from "constants/defaultPagination";
import { SERVICES } from "constants/environment";
import { prepareCommonFiltersForApi } from "modules/common/utils/prepareCommonFiltersForApi";
import { prepareHeadersForSCA } from "modules/common/utils/prepareHeadersForSCA";
import { preparePaginationParams } from "utils/preparePaginationParams";

import { prepareFiltersForApi } from "../utils/prepareFiltersForApi";

import type { RTKMeta } from "models/RTKMeta";
import type { CreatedByParams } from "modules/common/models/CreatedBy";
import type { UUID } from "utils";
import type { ObjectWithId } from "utils/ObjectWithId";
import type { PaginationParams } from "utils/preparePaginationParams";

import type { RefundsFilters } from "../constants";
import type {
  OrderableColumns,
  PaymentRefundStatus,
  Refund,
  RefundListItem,
} from "../models/Refund";

type GetRefundsResponse = {
  refunds: RefundListItem[];
  totalItems: number;
};

export type GetRefundsRequest = PaginationParams<OrderableColumns> &
  CreatedByParams & {
    customerHierarchyId?: UUID;
    filters?: Partial<RefundsFilters>;
    paymentId?: UUID;
  };

type PostApproveRefundResponse = {
  message: string;
};
type PostApproveRefundRequest = ObjectWithId & {
  scaToken?: string;
};

type PostApproveRefundsResponse = {
  message: string;
};
type PostApproveRefundsRequest = {
  refundIds: UUID[];
  scaToken?: string;
};

type PostRejectRefundResponse = {
  message: string;
};
type PostRejectRefundRequest = ObjectWithId;

type GetRefundStatusResponse = PaymentRefundStatus;
type GetRefundStatusRequest = ObjectWithId;

type GetPaymentRefundsRequest = {
  paymentId: UUID;
};
type GetPaymentRefundsResponse = Refund[];

type PostRefundRequest = {
  amount: number;
  paymentId: UUID;
  externalReference?: string;
};

type PostRefundResponse = ObjectWithId;

const extendedApi = portalApi.injectEndpoints({
  endpoints: (builder) => ({
    getRefunds: builder.query<GetRefundsResponse, GetRefundsRequest>({
      query: ({
        filters,
        customerHierarchyId,
        startDate,
        endDate,
        ...params
      }) => ({
        url: "portal/refunds",
        params: {
          customerHierarchyId,
          ...preparePaginationParams(params),
          ...prepareFiltersForApi(filters),
          ...prepareCommonFiltersForApi({ startDate, endDate }),
        },
      }),
      transformResponse: (refunds: RefundListItem[], meta: RTKMeta) => {
        const { totalItems } = getPaginationInfoFromHeaders(
          Object.fromEntries(meta.response.headers.entries())
        );

        return {
          refunds: addActiveKeyToRefundListItems(refunds),
          totalItems,
        };
      },
      extraOptions: {
        service: SERVICES.CONNECT,
      },
      providesTags: (_, __, { paymentId }) =>
        paymentId
          ? [{ type: TAGS.REFUNDS_LIST, paymentId }]
          : [TAGS.REFUNDS_LIST],
    }),
    approveRefund: builder.mutation<
      PostApproveRefundResponse,
      PostApproveRefundRequest
    >({
      query: ({ id, scaToken }) => {
        const headers = prepareHeadersForSCA({}, scaToken);

        return {
          url: `portal/refunds/${id}/approve`,
          method: "POST",
          headers,
        };
      },
      invalidatesTags: (_, __, { id }) => [
        { type: TAGS.REFUND_STATUS, id },
        TAGS.REFUNDS,
      ],
      extraOptions: {
        service: SERVICES.CONNECT,
      },
    }),
    approveRefunds: builder.mutation<
      PostApproveRefundsResponse,
      PostApproveRefundsRequest
    >({
      query: ({ refundIds, scaToken }) => {
        const headers = prepareHeadersForSCA({}, scaToken);

        return {
          url: `portal/refunds/approve`,
          method: "POST",
          body: {
            refunds: refundIds,
          },
          headers,
        };
      },
      invalidatesTags: (_, __, { refundIds }) => [
        ...refundIds.map((id) => ({ type: TAGS.REFUND_STATUS, id })),
        TAGS.REFUNDS,
        TAGS.REFUNDS_LIST,
      ],
      extraOptions: {
        service: SERVICES.CONNECT,
      },
    }),
    rejectRefund: builder.mutation<
      PostRejectRefundResponse,
      PostRejectRefundRequest
    >({
      query: ({ id }) => ({
        url: `portal/refunds/${id}/reject`,
        method: "POST",
        body: {
          reason: "Rejected by portal user",
        },
      }),
      invalidatesTags: (_, __, { id }) => [
        { type: TAGS.REFUND_STATUS, id },
        TAGS.REFUNDS,
      ],
      extraOptions: {
        service: SERVICES.CONNECT,
      },
    }),
    getRefundStatus: builder.query<
      GetRefundStatusResponse,
      GetRefundStatusRequest
    >({
      query: ({ id }) => ({
        url: `payments/${id}/refund-status`,
      }),
      providesTags: (_, __, { id }) => [{ type: TAGS.REFUND_STATUS, id }],
      extraOptions: {
        service: SERVICES.CONNECT,
      },
    }),
    postRefund: builder.mutation<PostRefundResponse, PostRefundRequest>({
      query: ({ paymentId, amount, externalReference }) => ({
        url: `/portal/refunds/${paymentId}`,
        method: "POST",
        body: { amount, externalReference },
      }),
      invalidatesTags: (_, __, { paymentId }) => [
        { type: TAGS.PAYMENT, paymentId },
        { type: TAGS.REFUND_STATUS, paymentId },
        TAGS.REFUNDS,
        TAGS.REFUNDS_LIST,
      ],
      extraOptions: {
        service: SERVICES.CONNECT,
      },
    }),
    getPaymentRefunds: builder.query<
      GetPaymentRefundsResponse,
      GetPaymentRefundsRequest
    >({
      query: ({ paymentId }) => ({
        url: `payments/${paymentId}/refunds`,
        params: DEFAULT_PAGINATION,
      }),
      transformResponse: transformPaymentRefundsResponse,
      providesTags: [TAGS.REFUNDS],
      extraOptions: {
        service: SERVICES.CONNECT,
      },
    }),
  }),
});

export const {
  useGetRefundsQuery,
  useApproveRefundMutation,
  useApproveRefundsMutation,
  useRejectRefundMutation,
  useGetRefundStatusQuery,
  usePostRefundMutation,
  useGetPaymentRefundsQuery,
} = extendedApi;

function addActiveKeyToRefundListItems(
  refunds?: Omit<RefundListItem, "active">[]
): RefundListItem[] {
  if (!refunds) {
    return [];
  }

  return refunds.map((refund) => ({
    ...refund,
    active: false,
  }));
}

function transformPaymentRefundsResponse(refunds: Refund[]) {
  return refunds.sort(
    ({ createdAt: dateA }, { createdAt: dateB }) =>
      new Date(dateB).getTime() - new Date(dateA).getTime()
  );
}
