import { CUSTOMER_DISCRIMINATOR } from "modules/customers/constants/Customer";

/**
 * @deprecated use translations in the customers module
 */
export const customers = {
  [CUSTOMER_DISCRIMINATOR.MERCHANT]: "Merchant",
  [CUSTOMER_DISCRIMINATOR.PSP]: "Payment service provider",
  [CUSTOMER_DISCRIMINATOR.TSP]: "Technical service provider",

  "Customer details": "Customer details",
  Country: "Country",
  Parent: "Parent",
  Type: "Type",
  "Company Registration Number": "Company Registration Number",
  "Document Number": "Document Number",
  "Document Number Label": "Set same value as above",
  "Incorporation Date": "Incorporation Date",
  checkoutBrandingInactiveMessage:
    "Branding is not active, checkout uses default Volt branding",
  companyLogoRequirements: "Please provide images, max. size: {{maxSizeMB}}MB",
  companyLogoRecommendedRatio: "Recommended aspect ratio {{ratio}}",
};
