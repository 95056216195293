/*eslint perfectionist/sort-objects: "error"*/

export const enGb = {
  checkoutBranding: "Checkout branding",
  companyLogoRecommendedRatioOtherwise:
    "Recommended aspect ratio {{ratio}}, otherwise it might not display properly",
  edit: "Edit",
  insideQR: "Inside QR code",
  selectCustomer: "Select customer",
  toViewCheckoutBrandingSelectCustomer:
    "To view Checkout branding preferences, please select a customer in the select customer menu in the upper left corner.",
};
