/*eslint perfectionist/sort-objects: "error"*/

export const enGb = {
  activeStateApplication:
    "Active - this application can be used to make payments",
  add: "Add",
  additionalPreferences: "Additional Preferences",
  application: "Application",
  applicationAllowConnect:
    "The client application allows you to connect to the Volt API.",
  applicationDetailsHeader: "Application Details",
  applicationDetailsTitle: "Application - {{name}}",
  applicationName: "Application Name",
  applicationNotFound: "Application not found",
  applications: "Applications",
  applicationWasActivated: "Application was activated successfully",
  applicationWasDeactivated: "Application was deactivated successfully",
  applicationWillBeDeleted:
    "Application will be deleted immediately and permanently.",
  appName: "App Name",
  backToList: "Back to all applications",
  cashManagement: "Cash Management",
  cashManagementNotificationsActivated:
    "Cash management notifications were activated successfully",
  cashManagementNotificationsDeactivated:
    "Cash management notifications were deactivated successfully",
  checkedElements: "{{checked}} / {{total}} elements",
  checkWhichAdditionalFieldsAreSelected:
    "Check which additional fields are selected for each status to be sent in notification webhook.",
  clientId: "Client ID",
  clientSecret: "Client Secret",
  configuration: "Configuration",
  confirm: "Confirm",
  createApplication: "Create application",
  createNewSecret: "Create new secret",
  createNewSignature: "Create a new signature",
  credentials: "Credentials",
  currentCredentialsWillBeInvalidated:
    "Current credentials will be invalidated immediately.",
  currentRedirectSignatureWillBeInvalidated:
    "Current redirect signature will be invalidated immediately",
  customer: "Customer",
  customerDoesNotHaveApplication:
    "{{customerName}} does not have any applications set up right now, lets get started",
  customerDoesNotHaveApplicationAndPermissions:
    "{{customerName}} does not have any applications set up right now and you don't have a permission to create one. Talk to support team if you think there is an issue",
  deactivationAppAgain:
    "Application can be deactivated again by switching the toggle.",
  deleteApplication: "Delete Application",
  details: "Details",
  dismiss: "Dismiss",
  doYouWantCreateNewRedirectSignature:
    "Do you want to create a new redirect signature?",
  doYouWantCreateNewSecret: "Do you want to create a new client secret?",
  doYouWantToDeactivateCashManagementNotifications:
    "Do you want to deactivate cash management notifications?",
  edit: "Edit",
  editApplication: "Edit application",
  editNotifications: "Edit notifications",
  findOutMoreAboutAdditionalNotificationContent:
    "<externalLink>Find out more about additional notification content.</externalLink>",
  generalInformation: "General information",
  generateNewClient: "Generate a new client secret",
  generateNewSignature: "Generate a new signature",
  generateRedirectSignature: "Generate a redirect signature",
  givenApplicationCouldNotFound:
    "Given application could not be found. Make sure you are on correct mode (production/sandbox).",
  inactiveStateApplication:
    "Inactive - this application cannot be used to make payments",
  notificationsAreActive:
    "Notifications are active and your application is receiving notifications.",
  notificationsAreInactive:
    "Notifications are inactive. Send a test notification to restart it.",
  notificationsCanBeReactivatedAgain:
    "Notifications can be reactivated again by switching the toggle.",
  notificationSettingsHaveBeenSaved:
    "Payment notification settings have been saved",
  notificationTestOutcome: "Notification test outcome",
  notificationURL: "Notification URL",
  ok: "Ok",
  onPaymentCancel: "On Payment Cancellation",
  onPaymentCancelTooltip:
    "If the payment cancellation URL is blank, a shopper who cancels their payment will be redirected to the provided failure URL.",
  onPaymentFailure: "On Payment Failure",
  onPaymentPending: "On Payment Pending",
  onPaymentSuccess: "On Payment Success",
  onVerificationCancel: "On Verification Cancel",
  onVerificationFailure: "On Verification Failure",
  onVerificationSuccess: "On Verification Success",
  paymentDescriptionInfo:
    "We use return URLs to send the customer to the correct page, based on which of these states the payment results in",
  paymentNotifications: "Payment Notifications",
  paymentReturnURLs: "Payment return URLs",
  productionCannotBeDeactivated: "Production application cannot be deactivated",
  reactivateApplicationAgain:
    "Application can be reactivated again by switching the toggle.",
  received: "Received",
  redirectSignature: "Redirect signature",
  redirectSignatureHasBeenSuccessfullyRegenerated:
    "Redirect signature has been successfully regenerated",
  redirectSignatureHelpsToEnsure:
    "Redirect signature helps to ensure that the payload was not modified",
  saveChanges: "Save Changes",
  seemsLikeNoAdditionalFieldsWereSelected:
    "Seems like no additional fields were selected for notifications.",
  selectedDataFieldsReceived:
    "Selected data fields will be sent to your payment notification URL when the payment is received into your Connect account.",
  selectedDataFieldsSuccessfulFailedPending:
    "Selected data fields will be sent to your payment notification URL when the payment was successful, failed or still pending.",
  selectWhichAdditionalFields:
    "Select which additional fields you would like for each status to be sent in notification webhook",
  status: "Status",
  successPendingOrFailure: "Success, pending or failure",
  testNotificationHasBeenSuccessful: "Test notification has been successful!",
  testNotificationHasFailed: "Test notification has failed.",
  testNotificationHasFailedTheFollowing:
    "Test notification has failed. The following error has been received:",
  theBanksBic8: "The bank's bic8.",
  theBanksBranchName:
    "The bank's branch name (where applicable), called branchName in the notification.",
  theBanksGroupOfficialName:
    "The bank's group official name, called groupName in the notification.",
  theCurrentPaymentStatus: "The current payment status.",
  theISOalpha2Code:
    "The ISO alpha-2 code of the country where the payment originated.",
  theSendersAccountNumber: "The sender's Account Number (where available).",
  theSendersBSB: "The sender’s BSB (for Australian payments, where available).",
  theSendersIBAN: "The sender's IBAN (where available).",
  theSendersName: "The sender's Name (where available).",
  theSendersPayId:
    "The sender’s PayID (for Australian payments, where available).",
  theSendersSortCode: "The sender's Sort Code (where available).",
  theVoltUUIDforTheBank:
    "The Volt UUID for the bank (and branch where applicable).",
  totalElements: "{{count}} element",
  totalElements_plural: "{{count}} elements",
  transactionDetails: "Transaction details",
  updateNotificationsSettings: "Update notifications settings",
  verificationDescriptionInfo:
    "Where your customer will be redirected after making a verification. You can use the same URL for two or all three states if you prefer.",
  verify: "Verify",
  verifyNotifications: "Verify Notifications",
  verifyNotificationSettingsHaveBeenSaved:
    "Verify notification settings have been saved",
  verifyNotificationURL: "Verify Notification URL",
  verifyReturnURLs: "Verify return URLs",
  wantToActivate: "Do you want to activate an application?",
  wantToDeactivate: "Do you want to deactivate an application?",
  wantToDelete: "Do you want to delete the application?",
  wrongPaymentUrlsMerchantWithoutPermission:
    "Seems like payment return URLs were not provided. Do talk to your administrator if you think there is an error",
  wrongPaymentUrlsMerchantWithPermission:
    "Seems like payment return URLs were not provided. Configure redirect addresses in order to process transactions with Volt",
  wrongPaymentUrlsVoltWithoutPermission:
    "Seems like payment return URLs were not provided. Do talk to Volt support team if you think there is an error",
  wrongPaymentUrlsVoltWithPermission:
    "Seems like payment return URLs were not provided. Configure redirect addresses in order to process transactions with Volt",
  wrongVerifyUrlsMerchantWithoutPermission:
    "Seems like Verify return URLs were not provided. Do talk to your administrator if you think there is an error",
  wrongVerifyUrlsMerchantWithPermission:
    "Seems like Verify return URLs were not provided. Configure redirect addresses in order to process Verifications with Volt",
  wrongVerifyUrlsVoltWithoutPermission:
    "Seems like return URLs were not provided. Do talk to Volt support team if you think there is an error",
  wrongVerifyUrlsVoltWithPermission:
    "Seems like Verify return URLs were not provided. Configure redirect addresses in order to process Verifications with Volt",
  youDoNotHaveApplication:
    "You don't have any applications set up right now, let's get started",
  youDoNotHaveApplicationAndPermissions:
    "You don't have any applications set up right now, do talk to your system administrator if you think there is an issue or our support team",
};
