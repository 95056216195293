/*eslint perfectionist/sort-objects: "error"*/

export const enGb = {
  byLoginBelowYouConsentToTppToInitiatePayment:
    "By login below, you consent to {{tpp}} to initiate a payment",
  cancel: "Cancel",
  confirm: "Confirm",
  continue: "Continue",
  goToLogInPage: "Go to log in page",
  login: "Login",
  logIn: "Log In",
  manageCookiePreferences: "Manage cookie preferences",
  paymentAuthentication: "Payment authentication",
  paymentDay: "Payment Date",
  paymentTotal: "Payment total",
  pleaseConfirmYourIdentityUsingSelectedSCAMethod:
    "Please confirm your identity using the selected SCA method.",
  pleaseConfirmYourRequestUsingSelectedSCAMethod:
    "Please confirm your request using the selected SCA method.",
  proxyLoginFailed: "Proxy login failed.",
  proxyLoginInvalidToken: "Proxy login invalid token.",
  reference: "Reference",
  returnToLogin: "Return to login",
  sca: "SCA",
  scaLoginHasBeenCancelled: "SCA login has been cancelled.",
  scaProcessHasBeenCanceledPleaseTryAgain:
    "SCA process has been canceled. Please try again.",
  setupSCA: "Set up SCA",
  somethingWentWrongPleaseTryAgainLaterOrContactSupport:
    "Something went wrong, please try again later or contact support.",
  strongCustomerAuthenticationHasBeenEnabled:
    "Strong customer authentication has been enabled.",
  tokenIsNotValid: "Token is not valid",
  transactionFinalizationSigningFailed:
    "An issue occurred during the finalization of transaction signing.",
  transactionInitiationSigningFailed:
    "An issue occurred during the initiation of transaction signing.",
  tryAgain: "Try again",
  twoFA: "2FA",
};
