import { currenciesMap } from "constants/currencies";

import type { Currency } from "models/Currency";

export const defaultCurrency = currenciesMap.get("EUR") as Currency;

export const currencies: Readonly<Currency[]> = Array.from(
  currenciesMap.values()
);

export { currenciesMap };
