import { styled, Typography } from "@mui/material";
import { Button } from "@volt_developers/components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { COMMON_TRANSLATION_KEYS } from "../../../../constants/commonTranslationsKeys";

import type { ReactNode } from "react";

export interface ConfigureCardHeaderProps {
  title: ReactNode;
  buttonTestId?: string;
  configureLink?: string | null;
}

export function ConfigureCardHeader({
  configureLink,
  title,
  buttonTestId,
}: ConfigureCardHeaderProps) {
  const { t } = useTranslation("common");

  return (
    <Wrapper>
      <Typography variant="h6">{title}</Typography>
      {configureLink ? (
        <Button.Wrapper
          component={Link}
          data-testid={buttonTestId ? buttonTestId : undefined}
          sx={{ mt: "auto" }}
          to={configureLink}
          variant="ghost"
        >
          <Button.Text>{t(COMMON_TRANSLATION_KEYS.configure)}</Button.Text>
        </Button.Wrapper>
      ) : null}
    </Wrapper>
  );
}

const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: theme.spacing(1.5),
}));
