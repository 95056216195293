import { DateTime } from "luxon";

import { currenciesMap, defaultCurrency } from "utils/currencies";

import { formatReportAmount } from "./formatReportAmount";
import { getReportFormatCurrency } from "./getReportFormatCurrency";

import type { CurrencyCode } from "../../../models/CurrencyCode";
import type { ApiReport, Report } from "../models/Reports";

interface Properties {
  country?: string[] | null;
  currency?: CurrencyCode[];
  customerHierarchyId?: string | null;
  excludeAbandoned?: boolean;
  licence?: string[] | null;
  maxAmount?: number | null;
  minAmount?: number | null;
  statuses?: string[] | null;
}

const stringifyLuxonDates = (createdAt: { gte?: string; lte?: string }) => {
  const { gte, lte } = createdAt;
  let startDate = gte
    ? DateTime.fromFormat(gte, "yyyyLLddHHmmss").toISO()
    : null;
  let endDate = lte ? DateTime.fromFormat(lte, "yyyyLLddHHmmss").toISO() : null;

  return { startDate, endDate };
};

const createParameters = (filters: ApiReport["filters"]) => {
  let properties: Properties = {};
  let currency: CurrencyCode[] | undefined;

  if (filters.currency) {
    currency = mapArrayOrStringToArray(filters.currency).map(
      (currencyId: string) =>
        currenciesMap.get(currencyId)?.id ?? defaultCurrency.id
    );

    properties.currency = currency;
  }

  const currencyFormat = getReportFormatCurrency(properties.currency);

  if (filters?.amount?.gte) {
    properties.minAmount = formatReportAmount(
      filters.amount.gte,
      currencyFormat
    );
  }

  if (filters?.amount?.lte) {
    properties.maxAmount = formatReportAmount(
      filters.amount.lte,
      currencyFormat
    );
  }

  if (filters.bank?.country) {
    properties.country = mapArrayOrStringToArray(filters.bank.country);
  }

  if (filters.licence) {
    properties.licence = filters.licence;
  }

  if (filters.metadata?.customerHierarchyId) {
    properties.customerHierarchyId = filters.metadata.customerHierarchyId;
  }

  if (filters.status) {
    properties.statuses = filters.status;
  }

  if (
    filters.excludeAbandoned !== undefined &&
    filters.excludeAbandoned !== null
  ) {
    properties.excludeAbandoned = filters.excludeAbandoned;
  }

  return properties;
};

export const mapReportsResponse = (reports: ApiReport[]) => {
  const mappedReports = reports.map((report) => {
    const filters = createParameters(report.filters);
    let dates = {};

    if (report.filters.createdAt) {
      dates = stringifyLuxonDates(report.filters.createdAt);
    }

    return {
      id: report.id,
      status: report.status,
      generatedAt: report.generatedAt,
      parameters: {
        ...dates,
        ...filters,
      },
    } as Report;
  });

  return mappedReports;
};

function mapArrayOrStringToArray(value: string | string[]): string[] {
  if (Array.isArray(value)) {
    return value;
  }

  return [value];
}
