import * as applicationsTranslations from "modules/applications/translations";
import * as authTranslations from "modules/auth/translations";
import * as bankAccountsTranslations from "modules/bank-accounts/translations";
import { enGb as checkoutTranslations } from "modules/checkout/translations/en-gb";
import { circuitBreaker as circuitBreakerOldTranslations } from "modules/circuit-breaker/i18n/en-GB/circuitBreaker";
import { enGb as circuitBreakerTranslations } from "modules/circuit-breaker/translations/en-gb";
import { connectTranslations } from "modules/connect";
import { enGb as customerTranslations } from "modules/customers/translations/en-gb";
import { enGb as dashboardTranslations } from "modules/dashboard/translations/en-gb";
import * as paymentsTranslations from "modules/payments/translations";
import { payoutsTranslations } from "modules/payouts";
import { refundsTranslations } from "modules/refunds";
import * as userManagementTranslations from "modules/user-management/translations";
import * as userSettingsTranslations from "modules/user-settings/translations";
import { enGb as workflowsTranslations } from "modules/workflows/translations/en-gb";

import { accounts } from "./accounts";
import { common } from "./common";
import { countries } from "./countries";
import { currencies } from "./currencies";
import { customers } from "./customers";
import { customersCheckoutBranding } from "./customersCheckoutBranding";
import { errors } from "./errors";
import { payments } from "./payments";

const index = {
  accounts,
  auth: authTranslations.enGb,
  applications: applicationsTranslations.enGb,
  bankAccounts: bankAccountsTranslations.enGb,
  circuitBreaker: Object.assign(
    circuitBreakerOldTranslations,
    circuitBreakerTranslations
  ),
  connect: connectTranslations.enGb,
  checkout: checkoutTranslations,
  common,
  countries,
  currencies,
  customers: Object.assign(customers, customerTranslations),
  customersCheckoutBranding,
  dashboard: dashboardTranslations,
  errors,
  payments: Object.assign(payments, paymentsTranslations.enGb),
  payouts: Object.assign(payoutsTranslations.enGb),
  refunds: refundsTranslations.enGb,
  workflows: workflowsTranslations,
  users: userManagementTranslations.enGb,
  userSettings: userSettingsTranslations.enGb,
};

export default index;
