/*eslint perfectionist/sort-objects: "error"*/

export const enGb = {
  activeItemsWillBeChecked:
    "Active items will be checked during payment processing, unless you change the status to Inactive.",
  add: "Add",
  addAllowlistItems: "Add Allowlist Items",
  addItem: "Add Item",
  addNewAllowlistItem: "Add new allowlist item",
  addNewItem: "Add New Item",
  addNewItem_plural: "Add {{count}} New Items",
  addToAllowlist: "Add to Allowlist",
  allowItemsHaveBeenCreated: "Allow item has been created",
  allowItemsHaveBeenCreated_plural: "Allow items have been created",
  allowlist: "Allowlist",
  areYouSureYouWantToChangeStatus:
    "Are you sure you want to change the status of selected items?",
  bankBranch: "Bank branch",
  bankCountry: "Bank country",
  blocklist: "Blocklist",
  chooseAndEnterTheItemYouWantAllowed:
    "Choose and enter the item you want allowed",
  circuitBreaker: "Circuit Breaker",
  circuitBreakerIsDisabledForThisCustomer:
    "Circuit Breaker is disabled for this Customer.",
  circuitBreakerIsInactive: "Circuit Breaker is inactive",
  circuitBreakerIsOurFraudPreventionTool:
    "Circuit Breaker is our fraud prevention tool. If you want to get access, please contact Volt support.",
  circuitBreakerWasNotActivatedGoToDetails:
    "Circuit Breaker was not activated for this customer. Go to customer details in order to enable the product.",
  circuitBreakerWasNotActivatedTalkToAdmin:
    "Circuit Breaker was not activated for this customer, do talk to your system administrator if you think there is an issue or our support team.",
  circuitBreakerWasNotActivatedTalkToSupport:
    "Circuit Breaker was not activated for this customer, do talk our support team if you think there is an issue.",
  currency: "Currency",
  deactivate: "Deactivate",
  documentId: "Document ID",
  emailAddress: "Email address",
  enterMoreThanOneCharacter: "Enter more than one character",
  failedToFetchUsers: "Failed to fetch users",
  fingerprint: "Fingerprint",
  goToAllowlist: "Go to Allowlist",
  goToCustomer: "Go to Customer",
  goToCustomers: "Go to Customers",
  iban: "IBAN",
  inactiveItemsWillBeChecked:
    "Inactive items will not be checked during payment processing, unless you change the status back to Active.",
  interestSubmitted: "Interest submitted",
  ip: "IP",
  items: "Items",
  itemsToAdd: "Items to add",
  limitRule: "Limit rule",
  loading: "loading...",
  noOptions: "No options",
  noResults: "No results",
  nothingToShowYet: "Nothing to show yet.",
  numAllowlistItemsSelected: "{{count}} allowlist item selected",
  numAllowlistItemsSelected_plural: "{{count}} allowlist items selected",
  numItemsHaveBeenActivated: "{{count}} item has been activated successfully",
  numItemsHaveBeenActivated_plural:
    "{{count}} items have been activated successfully",
  numItemsHaveBeenDeactivated:
    "{{count}} item has been deactivated successfully",
  numItemsHaveBeenDeactivated_plural:
    "{{count}} items have been deactivated successfully",
  payerId: "Payer ID",
  pleaseProvideValidValue: "Please, provide valid value",
  pleaseProvideValidValue_plural: "Please, provide valid values",
  retry: "Retry",
  riskRules: "Risk Rules",
  searchByValue: "Search by value",
  select: "Select",
  selectCustomer: "Select customer",
  selectCustomerToSeeMenu:
    "Select customer from the select customer menu to see Circuit Breaker menu.",
  setActive: "Set Active",
  setInactive: "Set Inactive",
  submitInterest: "Submit Interest",
  toViewAllowListSelectCustomer:
    "To view Allowlist, please select a customer in the select customer menu in the upper left corner.",
  toViewBlockListSelectCustomer:
    "To view Blocklist, please select a customer in the select customer menu in the upper left corner.",
  toViewRiskRulesSelectCustomer:
    "To view Risk Rules, please select a customer in the select customer menu in the upper left corner.",
  value: "Value",
  voltAdminUser: "Volt Admin user",
  weWereNotAbleToProceedWithActivation:
    "We were not able to proceed with activation. Please, try again later.",
  weWereNotAbleToProceedWithDeactivation:
    "We were not able to proceed with deactivation. Please, try again later.",
  yesSetActive: "Yes, Set Active",
  yesSetInactive: "Yes, Set Inactive",
  youCanOnlyAddUpTo: "You can only add up to {{maxAllowRules}} rules at a time",
  youDontHaveAnyAllowlistItemsGetStarted:
    "You don't have any allowlist items set up right now, let's get started.",
  youDontHaveAnyAllowlistItemsTalkToAdministrator:
    "You don't have any allowlist items set up right now, do talk to your system administrator if you think there is an issue or our support team.",
};
