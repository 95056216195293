import Logout from "../pages/Logout";

import type { Route } from "routes/RouteModel";

export const logoutRoute: Route = {
  // title for this path is not defined as this is only redirect route
  path: "/logout",
  hidden: true,
  component: Logout,
};
