/*eslint perfectionist/sort-objects: "error"*/

export const enGb = {
  accountIdentifier: "Account identifier",
  actions: "Actions",
  activate: "Activate",
  activateCustomer: "Activate customer",
  activateCustomerDescription:
    "Are you sure you want to activate customer account? The Customer will be able to access Fuzebox and process transactions once integration is set.",
  activateOn: "Activate on",
  activatingYourBeneficiaryWillMean:
    "Activating your beneficiary will mean you will be able to accept payments through Volt into that account?",
  active: "Active",
  activeProducts: "Active products",
  add: "Add",
  addAddress: "Add address",
  addADynamicBeneficiary: "Add a Dynamic Beneficiary",
  addANewCustomer: "Add a new customer",
  addBeneficiary: "Add Beneficiary",
  addBeneficiary_plural: "Add {{count}} Beneficiaries",
  addCustomer: "Add customer",
  addDynamicBeneficiary: "Add Dynamic Beneficiary",
  addedBy: "Added by",
  addEntry: "Add Entry",
  addNewDynamicBeneficiaries: "Add new dynamic beneficiaries",
  address: "Address",
  addressFor: "Address for {{name}}",
  addressLine2: "Address line 2",
  aliasValue: "Alias Value",
  allOfTheRealBankAccountsPopulated:
    "All of the real bank accounts have Bank Name populated.",
  applyFilters: "Apply filters",
  applyFiltersBeforeClose:
    "Before you close down the filter, do you want to leave without applying your filters?",
  areYouSureDeactivateCustomer:
    "Are you sure you want to deactivate this account? The customer will no longer be able to process transactions and access Fuzebox.",
  areYouSureYouWantProceedNotUsingVoltLicence:
    "Are you sure you want to proceed? Payments will no longer be processed using Volt PIS license, neither on Yapily, nor on PowerGrid.",
  areYouSureYouWantProceedUsingVoltLicence:
    "Are you sure you want to proceed? Payments will now be processed using Volt PIS license if applicable.",
  areYouSureYouWantToChangeStatus:
    "Are you sure you want to change the status?",
  areYouSureYouWantToStopCustomerDeactivation:
    "Are you sure you want to stop the customer deactivation? The Customer will be able to continue as normal.",
  atMidnight: "at 23:59 UTC",
  australia: "Australia",
  australiaAllowsReceivingPaymentsInAUD:
    "Australia as a product allows receiving payments on your website or app in AUD. It is processed using Australian market specific payment rails called PayTo.",
  authorized: "Authorized",
  backToList: "Back to all customers",
  bankCode: "Bank Code",
  beforeConfiguringSettlement:
    "Before configuring, the following tasks must be complete",
  billingParent: "Billing parent",
  brazilSettlement: "Brazil settlement",
  buildingNumber: "Building number",
  bySelectingAccountOutsideBrazil:
    "By selecting a bank account outside Brazil, you are registering this merchant's bank account at BEXS and setting the settlement to crossborder.",
  bySelectingBrazilianAccount:
    "By selecting a brazilian bank account, you are setting this merchant to domestic settlement.",
  cancel: "Cancel",
  changeDeactivationDate: "Change deactivation date",
  checkoutBranding: "Checkout branding",
  childCustomers: "Child customers",
  chooseDeactivationDate: "Choose deactivation date",
  circuitBreaker: "Circuit Breaker",
  circuitBreakerHasBeenActivated: "Circuit Breaker has been activated",
  circuitBreakerHasBeenDeactivated: "Circuit Breaker has been deactivated",
  circuitBreakerMonitorsEveryTransaction:
    "Circuit Breaker monitors every transaction, using a flexible scoring system and responsive fraudster detection, to catch the fraud before it catches you.",
  city: "City",
  clear: "Clear",
  clearingOptimisation: "Clearing Optimisation",
  clearingOptimisationTooltip:
    "Optimise your settlement rate by processing payments only via banks that Volt identifies as having high clearing rates",
  clientId: "Client ID",
  clientSecret: "Client Secret",
  close: "Close",
  closedLoopPayouts: "Closed Loop Payouts",
  companyRegistrationNumber: "Company registration number",
  configurationDetailsProvider: "Configuration details - {{provider}}",
  configurationForAutomaticApproval:
    "Configuration for automatic approval of payouts and refunds when initiating via API.",
  configure: "Configure",
  configurePaymentsProviders: "Configure payments providers",
  configureProvider: "Configure {{provider}}",
  confirm: "Confirm",
  connect: "Connect",
  connectOurRealTimeOrchestrationEngine:
    "Track open banking payments from initiation to receipt with complete visibility of the payment value chain. Connect, our real-time orchestration engine, collects and reconciles funds, handles exceptions, and processes payouts and refunds.",
  contactCustomerSupport: "contact customer support",
  continue: "Continue",
  couldNotFetchAccountInfos: "Could not fetch account informations.",
  couldNotLoadPaymentProcessingLicenses:
    "Could not load payment processing licenses",
  couldNotLoadProviderConfiguration: "Could not load payment providers.",
  couldNotLoadSettlementPreferences: "Could not load settlement preferences.",
  countries: "Countries",
  countriesFor: "Countries for {{customerName}}",
  countriesHaveBeenConfigured: "Countries have been configured.",
  country: "Country",
  credentials: "Credentials",
  customer: "Customer",
  customerAcceptsAutomaticApprovalOfPayouts:
    "Customer accepts automatic approval of payouts and refunds when initiating via API.",
  customerAcceptsPayments:
    "This customer accepts payments from selected countries.",
  customerActivated: "Customer activated",
  customerAddress: "Customer address",
  customerAddressProvided: "Customer address provided.",
  customerDeactivated: "Customer deactivated",
  customerDetails: "Customer details",
  customerHasBeenCreated: "{{customerName}} has been created",
  customerName: "Customer {{name}}",
  customerNameCardLabel: "Customer name",
  customers: "Customers",
  customerStatus: "Customer status",
  customerStatusActive:
    "Active - customer can access Fuzebox and make payments",
  customerStatusDeactivated:
    "Deactivated - customer cannot access Fuzebox and also cannot make payments",
  customerType: "Customer Type",
  customerWasSaved: "{{customerName}} was saved",
  dateAdded: "Date added",
  deactivate: "Deactivate",
  deactivateCustomer: "Deactivate customer",
  deactivateImmediately: "Deactivate immediately",
  deactivatingYourBeneficiaryWillMean:
    "Deactivating your beneficiary will mean you will no longer be able to accept payments through Volt into that account?",
  deactivationDate: "Deactivation Date",
  deactivationDateCannotBeInThePast: "Deactivation date cannot be in the past",
  details: "Details",
  disableVoltLicence: "Disable Volt PIS license",
  documentNumber: "Document number",
  doNotApplyFilters: "Don’t apply",
  dynamicBeneficiaries: "Dynamic beneficiaries",
  dynamicBeneficiariesHaveBeenUpdated: "Dynamic beneficiary has been updated",
  dynamicBeneficiariesHaveBeenUpdated_plural:
    "Dynamic beneficiaries have been updated",
  dynamicBeneficiariesUpdateFailed: "Dynamic beneficiaries update failed.",
  dynamicBeneficiariesWereUpdated: "Dynamic beneficiaries have been updated.",
  dynamicBeneficiary: "Dynamic beneficiary",
  edit: "Edit",
  editCustomer: "Edit customer",
  enableVoltLicence: "Enable Volt PIS license",
  enablingUnitedStatesWillAllowPaymentsInitiation:
    "Enabling United States will allow the initiation of payments, plus refunds and payouts, in USD. Payments will be processed using ACH and are not instant.",
  environment: "Environment",
  experienceTheConvenienceACH:
    "Experience the convenience of accessing funds on the very same day, provided transactions are initiated before the ACH cut-off time",
  expirationDate: "Expiration date",
  failedToLoadUsernames: "Failed to load usernames. Please, try again later.",
  fastTrackWithSameDayACH: "Fast-track transactions with Same Day ACH",
  filter: "Filter",
  findOutMore: "Find out more",
  fraudPreventionACH: "Fraud prevention for ACH payments",
  gateway: "Gateway",
  generalInformation: "General information",
  generate: "Generate",
  givenCustomerCouldNotBeFound: "Given customer could not be found.",
  goToBeneficiaries: "Go to Beneficiaries",
  guestCheckoutOnly: "Guest checkout only",
  hierarchy: "Hierarchy",
  iban: "IBAN",
  id: "ID",
  ignore: "Ignore",
  inactive: "Inactive",
  inactiveProducts: "Inactive products",
  incorporationDate: "Incorporation date",
  incorporationDateCompanyDocumentPopulated:
    "Incorporation date, company registration number and document number are all populated.",
  indicatesIfTheCurrentMerchantIsApprovedToProcessPayments:
    "Indicates if the current merchant is approved to process payments using Volt PIS licence. Determined and set by the compliance team.",
  integrator: "Integrator",
  integratorIsInheritedFromLoading:
    "Integrator is being inherited from <Loading />",
  integratorIsInheritedFromParent:
    "Integrator is being inherited from {{parent}}",
  integratorName: "Integrator Name",
  invalidPublicKey: "Invalid public key",
  issueClosedLoopPayoutsToYourShoppersFromYourVoltConnectAccount:
    "Issue closed loop payouts to your shoppers from your Volt Connect account",
  issueOpenLoopPayoutsToYourShoppersFromYourVoltConnectAccount:
    "Issue open loop payouts to your shoppers from your Volt Connect account",
  learnMoreAboutVoltProducts: "Learn about the other products Volt offers",
  legalName: "Legal name",
  listOfAllDependentCustomers: "List of all dependent customers ",
  machineLearningPoweredMatchmeter:
    "Machine learning-powered Matchmeter tool accurately matches naming nuances between merchant inputs and end-user bank records, producing a percentage match score.",
  manageAllYourEnabledProducts: "Manage all your enabled products",
  matchmeter: "Matchmeter",
  merchantCategoryCode: "Merchant category code",
  merchantCustomers: "Merchant customers",
  more: "+ {{numberOfInVisibleTags}} more",
  moreFiltersText: "+{{count}} more",
  name: "Name",
  newCustomerCredentials:
    "New customer credentials for {{envName}} Environment",
  noAccountSelected: "No account selected",
  noCustomerFoundInThisHierarchy: "No customer found in this hierarchy",
  nonExpiring: "Non-expiring",
  noPublicKeys: "No public keys",
  noResults: "No results",
  numDynamicBeneficiariesSelected: "{{count}} Dynamic Beneficiary selected",
  numDynamicBeneficiariesSelected_plural:
    "{{count}} Dynamic Beneficiaries selected",
  onlyUseInstantPaymentSchemes: "Only use instant payment schemes",
  onSelectedDateMerchantWillBeDeactivated:
    "On the selected date, the merchant's account and all child accounts will be deactivated. The Merchant will no longer be able to process payments via Volt, users will lose access to Fuzebox and transactional data so we suggest exporting this data in advance.",
  open: "Open",
  openLoopPayouts: "Open Loop Payouts",
  ourAIStool: "Our AIS tool",
  ourBINRecognitionTool: "Our BIN recognition tool",
  ourCashManagementSystem: "Our cash management system",
  ourCoreIntegration: "Our core integration",
  ourFraudPreventionTool: "Our fraud prevention tool",
  ourIntelligentNameMatching: "Our intelligent name matching",
  ourSolutionForAustralia: "Our solution for Australian market",
  ourSolutionForUS: "Our solution for US market",
  parent: "Parent",
  parentSubTppId: "Parent SubTppId",
  password: "Password",
  payByLink: "Pay by Link",
  payByLinkEnablesBusinessesToIntroduceOpenBankingPayments:
    "Pay by Link enables businesses to introduce open banking payments to all their communication channels with their customers. Clicking on the pre-generated link or scanning the QR code allows the customer to be redirected to Volt’s checkout page, where they can pay instantly using open banking-powered payments.",
  paymentConfiguration: "Payment configuration",
  paymentPreferences: "Payment preferences",
  paymentProcessingLicence: "Payment processing license",
  paymentProviderConfigured:
    "Payment provider {{name}} has been configured for the customer.",
  paymentProviders: "Payment providers",
  paymentProviderUpdated:
    "Payment provider {{name}} has been updated for the customer.",
  payments: "Payments",
  paymentSchemes: "Payment Schemes",
  paymentsProviders: "Payments providers",
  payouts: "Payouts",
  payoutsIsAFastAndReliablePaymentProcessingSolution:
    "Payouts is a fast and reliable payment processing solution that enables businesses to send money to individuals with ease.",
  pendingApprovalFromToken: "Pending approval from Token",
  "PIS: eCommerce merchant payment": "PIS: eCommerce merchant payment",
  "PIS: Funding an account": "PIS: Funding an account",
  "PIS: In store merchant payment": "PIS: In store merchant payment",
  "PIS: Paying a bill": "PIS: Paying a bill",
  "PIS: Paying off debt": "PIS: Paying off debt",
  pleaseCopyAndSecurely:
    "Please copy and securely store the credentials, viewing the client password will not be possible after closing this popup.",
  pleaseSelectParent:
    "Please select a parent to see the list of bank accounts this customer can inherit.",
  postalCode: "Postal code",
  postalZIPCode: "Postal / ZIP Code",
  primaryUseCase: "Primary Use Case",
  processOnlyInstantPaymentsVerifiedByVolt:
    "Process only instant payments Verified by Volt",
  productFlagProductionModeHasBeenActivated:
    "{{productFlagName}} production mode has been activated",
  productFlagProductionModeHasBeenDeactivated:
    "{{productFlagName}} production mode has been deactivated",
  productFlagSandboxModeHasBeenActivated:
    "{{productFlagName}} sandbox mode has been activated",
  productFlagSandboxModeHasBeenDeactivated:
    "{{productFlagName}} sandbox mode has been deactivated",
  production: "Production",
  provider: "Provider",
  provideValidBankCodes:
    "Provide valid bank codes to configure the list of allowed beneficiary banks.",
  publicKey: "Public key",
  publicKeyConfiguration: "Public key configuration for {{customerName}}",
  publicKeyHasBeenCreated: "Public key has been created",
  publicKeyId: "Public key id",
  publicKeys: "Public keys",
  reduceFraudWithSignal:
    "Reduce fraud with Signal's advanced machine learning, built on Plaid's trusted open banking network.",
  refundPaymentsReceivedOnYourVoltConnectAccount:
    "Refund payments received on your Volt Connect account",
  refunds: "Refunds",
  regenerate: "Regenerate",
  regenerateCredentials: "Regenerate credentials",
  regenerateCustomerCredentials:
    "Regenerate customer credentials for {{envName}} Environment?",
  registerAtBEXS: "Register at BEXS",
  rememberToIncludeBeginAndEndIndicators:
    "Remember to include begin and end indicators",
  requiresSetup: "Requires Setup",
  reset: "Reset",
  retry: "Retry",
  sameDayACH: "Same day ACH",
  sandbox: "Sandbox",
  save: "Save",
  saveChanges: "Save",
  select: "Select",
  selectBankAccountYouWishToReceiveMoney:
    "Select a bank account you wish to receive money to. Selecting a bank account outside Brazil will register this merchant’s bank account at BEXS and set the settlement to crossborder.",
  selectCustomer: "Select customer",
  selectedCustomerHasBeenReset: "Selected customer has been reset",
  sendSecurePaymentLinks: "Send secure payment links",
  setActive: "Set Active",
  setInactive: "Set Inactive",
  settlementBankAccount: "Settlement bank account",
  settlementPreferencesUpdated: "Settlement preferences have been updated.",
  setUpDynamicBeneficiary: "Set up dynamic beneficiary",
  showLess: "Show Less",
  signal: "Signal",
  somethingWentWrong: "Something went wrong. Please try again later.",
  sort_code: "Sort Code",
  sortCode: "Sort code",
  state: "State",
  stateRegionProvince: "State / Region / Province",
  status: "Status",
  stopDeactivation: "Stop deactivation",
  street: "Street",
  swift: "Swift code (BIC)",
  theCustomerWillNoLonger:
    "The customer will no longer be able to process transactions or access Fuzebox after the selected date.",
  theExistingCredentials:
    "The existing credentials will be invalidated immediately",
  thereAreMoreFiltersItems: "There is {{count}} more item",
  thereAreMoreFiltersItems_plural: "There are {{count}} more items",
  theTokenIoGeneratedIdentifierForTheParentSubTpp:
    "The Token.io-generated identifier for the parent sub-TPP. Please check Token documentation as a reference.",
  thisAccountDeactivatingOn: "This account is deactivating on",
  thisActionWillCauseToRegisterOnBEXS:
    "This action will cause us to register this customer and their bank accounts on BEXS. Please make sure that BEXS have already accepted this customer and allowed them before accepting.",
  thisConfigurationIsCommonForTokenAndVoltLicence:
    "This configuration is common for Token and Volt licence.",
  thisConfigurationIsCommonForTokenAndVoltLicenceDataForGivenMerchant:
    "This configuration is common for Token and Volt licence. Data for the given merchant has been already provided.",
  thisCustomerDoesNotHaveAddress: "This customer doesn't have an address",
  thisCustomerDoesNotHaveAddressContactSupport:
    "This customer doesn't have an address, to add this information, please ",
  thisCustomerDoesNotHaveDynamicTalkSupport:
    "This customer does not have any dynamic beneficiaries set up right now, talk to customer support team if you think there’s an issue",
  thisCustomerDoesNotHaveSelectedCountries:
    "This customer does not have selected countries from which payments are accepted.",
  thisParentDoesntHaveBankAccountsAssigned:
    "This parent doesn’t have any bank accounts assigned.",
  transformer: "Transformer",
  transformerRecognisesAShopperBank:
    "Transformer recognises a shopper’s bank based on their debit or bank-issued credit card’s first six to eight digits (BIN). It’s then able to facilitate a ‘switch’ from a card payment to an open banking payment.",
  type: "Type",
  unknown: "Unknown",
  us: "United States",
  username: "Username",
  validationFailedIcon: "Validation failed icon",
  validationSuccessfulIcon: "Validation successful icon",
  verify: "Verify",
  verifyEnablesYouToSecurelyAccessCustomerBankAccounts:
    "Verify enables you to securely access customer bank accounts to confirm a user’s full name and account number. Compared to manual processes and working with unstructured data, it’s faster, more affordable and saves valuable time.",
  view: "View",
  voltAdminUser: "Volt admin user",
  voltCustomerId: "Volt Customer ID",
  voltGatewayOurCoreAPIisTheStartingPointForReceivingPaymentsOnYourWebsiteOrApp:
    "Volt Gateway, our core API, is the starting point for receiving payments on your website or app. Whether you’re planning to receive money direct to your own bank account, or use the power of Volt Connect, our cash management service, this is the first integration point.",
  voltLicenceHasBeenDisabled: "Volt PIS license has been disabled",
  voltLicenceHasBeenEnabled: "Volt PIS license has been enabled",
  voltLicenceManagementAvailableOnYapilyAndPowerGrid:
    "Volt PIS license management is available on Yapily and PowerGrid only. To process transactions on a Volt PIS license, you need to firstly enable Yapily or PowerGrid on a Payment providers list.",
  voltPisLicence: "Volt PIS license",
  weWereNotAbleToActivate:
    'We were not able to activate Token. The following error has been received: "AWAITING_APPROVAL". Please, contact Token Support.',
  yesSetActive: "Yes, Set Active",
  yesSetInactive: "Yes, Set Inactive",
  youCanIssueARefundForEachPayment:
    "You can issue a refund for each payment received on your Volt Connect account. Refunds are available for up to a year from the original payment date. The refund will always be sent to the bank account that originated the payment and it will be sent from your current Volt Connect account in the currency of the payment.",
  youCanOnlyAddUpTo:
    "You can only add up to {{maxBeneficiaries}} beneficiaries at a time",
  youCurrentlyHaveSelectedCustomer:
    "You currently have a selected customer applied that is limiting the customers type you see. To view all customers type, please clear the filter.",
  youDoNotHavePermissionToManage:
    "You do not have permission to manage bank accounts. Contact Volt administrator if you think there is an error.",
  youDoNotHavePermissionToViewOrManage:
    "You do not have permissions to view or manage bank accounts. Contact Volt administrator if you think there is an error.",
  youDontHaveAnyDynamicBeneficiariesGetStarted:
    "You don’t have any dynamic beneficiaries set up right now, let’s get started",
  youDontHaveAnyPublicKeyUploadedYet:
    "You don't have any public key uploaded yet",
  youHaveUnsavedFilters: "You have unsaved filters",
  yourAddressDataIsMissing:
    "Your address data is missing. Please contact Volt Support for more details.",
};
