/*eslint perfectionist/sort-objects: "error"*/

export const enGb = {
  actionRequiredWeHaveUpdated:
    "Action required: we\u2019ve updated our evaluation licence agreement",
  agreeAndAccess: "Agree and access",
  email2FAIsEnabledForVoltAdmins: "Email 2FA is enabled for Volt Admins",
  enabled: "Enabled",
  enableSCA: "Enable SCA",
  evaluationLicenceAgreementForVolt:
    "Evaluation licence agreement for Volt Sandbox environment",
  forMultipleDevicesAndBrowsersUseYourPhoneTabletOrUSBSecurityKeyAsAPasskey:
    "For multiple devices and browsers, use your phone, tablet or USB security key as a passkey",
  logout: "Logout",
  pleaseReadCarefullyBeforeAccessing:
    "Please read carefully before accessing the Volt Sandbox Environment",
  readLicenceAgreementFirst: "Read licence agreement first",
  scaStatus: "SCA status",
  status2FA: "2FA status",
  strongCustomerAuthentication: "Strong customer authentication",
  toAccessTheVoltSandboxEnvironment:
    "To access the Volt Sandbox Environment, you must agree to the Volt Evaluation Licence which will bind you and the company which you are accessing the Volt Sandbox Environment on behalf of (the “Company”). Please ensure you are authorised to enter into the agreement on behalf of the Company. If you do not agree to the terms of the agreement, or are not authorised to do so, you should not click the accept button below and you may not use the Volt Sandbox Environment. The Volt Sandbox Environment is not for personal use and you should not register to use it if you are a consumer.",
  toHaveAccessToAllFunctionalitiesPleaseActivateSCA:
    "To have access to all functionalities, please activate SCA. Disabling this option will require contacting the support team.",
  twoFactorAuthentication: "Two-factor authentication",
  userSettings: "User Settings",
};
